<template>
  <profile-layout :title="$t('offers.title')" :is-loading="false" :show-menu="false">
    <template #content>
      <div id="member-offers" class="row pb-4 mb-4">
        <div class="col-12">
          <h3 class="fs-5 fs-md-4">{{ $t('offers.members.title') }}</h3>
          <p>{{ $t('offers.members.intro') }}</p>
          <router-link :to="{path: ''}" class="btn btn-sm btn-outline-secondary" @click.prevent="scrollTo('club-offers')">
            {{ $t('offers.clubs.link') }} <i class="bi bi-arrow-down"></i>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="commonninja_component pid-4de12539-43bd-4cc8-9ac9-5cc0e72d568b" v-if="$i18n.locale === 'de'"></div>
        <div class="commonninja_component pid-3053461f-5f30-459c-a413-e2baf396a45c" v-else></div>
      </div>

      <hr id="club-offers" class="my-5">

      <div class="row pb-4 mb-4">
        <div class="col-12">
          <h3 class="fs-5 fs-md-4">{{ $t('offers.clubs.title') }}</h3>
          <p>{{ $t('offers.clubs.intro') }}</p>
          <router-link :to="{path: ''}" class="btn btn-sm btn-outline-secondary" @click.prevent="scrollTo(null)">
            {{ $t('offers.members.subtitle') }} <i class="bi bi-arrow-up"></i>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="commonninja_component pid-b2b4807d-773e-4e63-ab99-e6ffe984723e" v-if="$i18n.locale === 'de'"></div>
        <div class="commonninja_component pid-1dfa237d-fd68-497b-b236-f379f8612f1a" v-else></div>
      </div>

    </template>
  </profile-layout>
</template>

<script>
import ProfileLayout from "@/components/profile/ProfileLayout.vue";

export default {
  components: {
    ProfileLayout,
  },
  methods: {
    scrollTo(id) {
      let offset;
      if (id) {
        const el = document.getElementById(id);
        const elPos = el.offsetTop;
        offset = elPos - 74;
      } else {
        offset = 0;
      }
      window.scrollTo(0, offset);
    },
  },
}
</script>

<style lang="scss" scoped>
.offer-item {
  transition: all 500ms;

  &:hover {
    transform: scale(1.05);
  }

  a {
    border-radius: 10px;
    overflow: hidden;
  }

}
</style>
