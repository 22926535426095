<template>
  <club-layout :title="clubData.status === 200 ? $t('club.profile') + ' ' + clubData.data.Name : $t('club.notFound.title')" :is-loading="false">
    <template #content v-if="clubData.status === 200">

      <div class="col-12">
        <DataTable :value="clubSponsorsAndPartners"
          :sortField="sortColumn" :sortOrder="1" @sort="onSort"
          v-model:selection="selection"
          dataKey="memberId"
          v-model:filters="filters" filterDisplay="row"
          :loading="isLoading"
          ref="dt"
          paginator :rows="50" :rowsPerPageOptions="[50, 100, 150, 200]"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
          :currentPageReportTemplate="`{first}` + ' ' + $t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + $t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
          csvSeparator=";"
          scrollable scrollHeight="35rem">

          <template #empty>{{ $t('section.clubMembers.noSponsorsFound') }}</template>
          <template #paginatorfirstpagelinkicon>
            <i class="bi bi-arrow-bar-left" />
          </template>
          <template #paginatorprevpagelinkicon>
            <i class="bi bi-arrow-left" />
          </template>
          <template #paginatornextpagelinkicon>
            <i class="bi bi-arrow-right" />
          </template>
          <template #paginatorlastpagelinkicon>
            <i class="bi bi-arrow-bar-right" />
          </template>

          <Column selectionMode="multiple" class="dt-col-2"></Column>

          <Column field="memberId" filterField="memberId" :header="$t('club.listMember.nr')" sortField="memberId" sortable :headerClass="(sortColumn === 'memberId' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" :exportable="true">
            <template #body="{ data }">
              {{ formatEntry('String', data.memberId) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.listMember.nr')})" class="form-control input-filter" />
            </template>
          </Column>

          <Column field="name" filterField="name" :header="$t('club.listSponsor.name')" sortField="name" sortable :headerClass="(sortColumn === 'name' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" :exportable="true">
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-sponsor', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link">
                {{ formatEntry('String', data.name) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.listSponsor.name')})" class="form-control input-filter" />
            </template>
          </Column>

          <Column field="name2" filterField="name2" :header="$t('club.listSponsor.name2')" sortField="name2" sortable :headerClass="(sortColumn === 'name2' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" :exportable="true">
            <template #body="{ data }">
                {{ formatEntry('String', data.name2) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.listSponsor.name2')})" class="form-control input-filter" />
            </template>
          </Column>

          <Column field="address" fieldField="address" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.address')" sortField="address" sortable :headerClass="(sortColumn === 'address' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" :exportable="true">
            <template #body="{ data }">
                {{ formatEntry('String', data.address) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.listMember.address')})" class="form-control input-filter" />
            </template>
          </Column>

          <Column field="postCode" fieldField="postCode" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.zipCode')" sortField="postCode" sortable :headerClass="(sortColumn === 'postCode' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" :exportable="true">
            <template #body="{ data }">
                {{ formatEntry('String', data.postCode) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.listMember.zipCode')})" class="form-control input-filter" />
            </template>
          </Column>

          <Column field="city" fieldField="city" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.place')" sortField="city" sortable :headerClass="(sortColumn === 'city' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" :exportable="true">
            <template #body="{ data }">
                {{ formatEntry('String', data.city) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.listMember.place')})" class="form-control input-filter" />
            </template>
          </Column>

          <Column field="emailPrivate" fieldField="emailPrivate" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.eMailPrivate')" sortField="emailPrivate" sortable :headerClass="(sortColumn === 'emailPrivate' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" :exportable="true">
            <template #body="{ data }">
                {{ formatEntry('String', data.emailPrivate) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.listMember.eMailPrivate')})" class="form-control input-filter" />
            </template>
          </Column>

          <Column field="emailAlternative" fieldField="emailAlternative" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.eMailAlternative')" sortField="emailAlternative" sortable :headerClass="(sortColumn === 'emailAlternative' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" :exportable="true">
            <template #body="{ data }">
                {{ formatEntry('String', data.emailAlternative) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: $t('club.listMember.eMailAlternative')})" class="form-control input-filter" />
            </template>
          </Column>
        </DataTable>

        <div class="row w-100 flex-center mb-3 mt-3">
          <Button title="Export" label="Export" @click="exportCSV($event)" class="datatable-export me-1 width-auto" severity="secondary" outlined>
            <i class="bi bi-filetype-csv"></i>
          </Button>
          <Button title="New" label="New" class="me-1 width-auto" severity="primary" outlined @click="newContact" onkeypress="newContact">
            <i class="bi bi-person-plus"></i>
          </Button>
        </div>
      </div>
      <LoaderOverlay v-if="isLoading"/>

      <div class="row">
        <FaqPanel class="m-0 border-0"/>
      </div>
      <BootstrapToast />
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </club-layout>
</template>

<script setup>

import {computed, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {Tooltip} from "bootstrap";
import {FilterMatchMode} from "@primevue/core/api"
import {useMemberStore} from "@/store/modules/member/member.js";
import {useClubStore} from "@/store/modules/club/club.js";
import {useI18n} from "vue-i18n";

import FaqPanel from "@/components/ui/FaqPanel.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import Button from 'primevue/button';
import ClubLayout from "@/components/club/ClubLayout";

import moment from "moment/moment";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";

const dt = ref();
const route = useRoute()
const router = useRouter()
const clubStore = useClubStore();
const memberStore = useMemberStore();
const i18nLocale = useI18n().locale.value;

const clubData = computed(() => clubStore.getClubData);

const filters = ref({
  memberId: {value: null, matchMode: FilterMatchMode.CONTAINS},
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name2: { value: null, matchMode: FilterMatchMode.CONTAINS },
  socialInsuranceNr: { value: null, matchMode: FilterMatchMode.CONTAINS },
  swissAthleticsNr: { value: null, matchMode: FilterMatchMode.CONTAINS },
  salutation: { value: null, matchMode: FilterMatchMode.CONTAINS },
  language: { value: null, matchMode: FilterMatchMode.CONTAINS },
  address: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  city: { value: null, matchMode: FilterMatchMode.CONTAINS },
  emailPrivate: { value: null, matchMode: FilterMatchMode.CONTAINS },
  emailAlternative: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const isSelectable = ref(false);
const isLoading = ref(false);
const sortColumn = ref('memberId');
const selection = ref([]);

const vereinName = computed(() => {
  let club = null;
  if (memberData.value.functions) {
    club = memberData.value.functions.find(f => {
      return !!f && f.organId === currentClubId.value;
    });
  }
  return club != null && club.organ !== null ? club.organ : '';
})

const memberData = computed(() => {
  return memberStore.getMemberData;
})

const clubSponsorsAndPartners = computed(() => {
  return clubStore.getClubSponsorsAndPartners
})

const currentClubId = computed(() => {
  return clubStore.getCurrentClubId;
})

const onSort = (event) => {
  sortColumn.value = event.sortField;
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Array':
      return [ ...new Set(Array.prototype.map.call(value, s => s.description)) ].join(', ');
    default:
      return value;
  }
}

const reloadClubSponsorsAndPartners = (clubId) => {
  if (clubId !== currentClubId.value) {
    isLoading.value = true;
    clubStore.setCurrentClubId(clubId);
    clubStore.setCurrentClubName(vereinName.value);
    clubStore.loadClubListSponsorsAndPartners(clubId, false, true, true, i18nLocale).then(() => {
      isLoading.value = false;
    });
  }
}

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}
const exportCSV = () => {
  dt.value.exportCSV({selectionOnly:selection.value.length>0});
}

const newContact = () => {
  isLoading.value = true;
  router.push({
          name: 'club-edit-sponsor',
          query: {clubId: clubStore.getCurrentClubNo, memberId: 'new'}
        });
}

watch(
  () => route.query.clubId,
  async newClubId => {
    reloadClubSponsorsAndPartners(newClubId)
  }, { immediate: true }
)

onMounted(() => {
  isSelectable.value = selection.value.length > 0;
  new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
  })
})
</script>
