<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="mb-3">{{ $t('breadcrumb.club.members') }} {{ institute.data.Description }}</h5>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <DataTable :value="instituteAddressList"
                   scrollable scroll-height="60vh"
                   :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                   data-key="Institute_Code"
                   v-model:filters="filters" filter-display="row"
                   :loading="!instituteAddressList"
                   csv-separator=";"
                   ref="dt" :export-filename="$t('institutes.list.title').replaceAll(' ', '_')" :export-function="csvExportTransformationFunction"
                   :paginator="instituteAddressList.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                   paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                   :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                   class="border-bottom">
          <template #empty>
            <div class="alert alert-info">{{ $t('institutes.addresses.list.noData') }}</div>
          </template>
          <template #paginatorfirstpagelinkicon>
            <i class="bi bi-arrow-bar-left"/>
          </template>
          <template #paginatorprevpagelinkicon>
            <i class="bi bi-arrow-left"/>
          </template>
          <template #paginatornextpagelinkicon>
            <i class="bi bi-arrow-right"/>
          </template>
          <template #paginatorlastpagelinkicon>
            <i class="bi bi-arrow-bar-right"/>
          </template>

          <Column>
            <template #body="{ data }">
              <div class="btn-group btn-group-sm pe-2">
                <Button :title="$t('institutes.functions.edit', {functionName: data.functionDescription})"
                        unstyled class="btn btn-outline-secondary"
                        :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm"
                        @click="showInstituteAddressEditDialog(data)">
                  <i class="bi bi-pencil"></i>
                </Button>
                <Button :title="$t('institutes.members.delete.label')"
                        unstyled class="btn btn-outline-primary"
                        @click="confirmDelete($event, data)">
                  <i class="bi bi-trash"></i>
                </Button>
              </div>
            </template>
          </Column>

          <Column v-for="col in columns" :key="col.field" :field="col.field"
                  :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                  :header="col.header"
                  :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                  :exportable="col.exportable"
                  :hidden="!col.visible">
            <template #body="{data}">
              {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
            </template>
            <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
            </template>
          </Column>

        </DataTable>
      </div>
    </div>

    <div class="row w-100 flex-center gap-2 mb-3 mt-3">
      <router-link :to="groupRoute" class="btn btn-outline-secondary width-auto">
        <i class="bi bi-arrow-left me-2"></i><span class="p-button-label">{{ $t('back') }}</span>
      </router-link>
      <Button title="Export" label="Export" @click="exportCSV($event)" unstyled class="btn btn-outline-primary datatable-export width-auto">
        <i class="bi bi-filetype-csv"></i>
      </Button>
      <Button :title="$t('institutes.members.add')" :label="$t('institutes.members.add')" :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm me-2"
              unstyled class="btn btn-outline-secondary width-auto" icon="bi bi-plus-circle me-2" outlined @click="showInstituteAddressNewDialog()"/>
      <router-link :to="functionRoute" class="btn btn-outline-secondary width-auto">
        <span class="p-button-label">{{ $t('institutes.functions.manage') }}</span>
      </router-link>
    </div>

    <Dialog v-model:visible="showInstituteAddressModal" modal block-scroll :header="$t('institutes.members.edit.title')" :style="{width: '30rem'}" @hide="resetInstituteAddress">
      <div class="form-row mb-3" v-focustrap>
        <label for="functionCode" class="form-label">{{ $t('institutes.functions.selectFunction') }}</label>
        <InputGroup class="w-100">
          <Select input-id="functionCode" v-model="selectedInstituteAddress.Institute_Function_Code"
                  autofocus
                  :tabindex="1"
                  :options="instituteFunctionList.data"
                  option-value="Code"
                  option-label="Description"
                  filter
                  :disabled="!selectedInstituteAddress.new"
                  :placeholder="$t('institutes.functions.selectFunction')"
                  :invalid="!selectedInstituteAddress.Institute_Function_Code"
                  @keyup.enter="saveInstituteAddress"/>
          <Button :title="$t('institutes.functions.create')" severity="primary" icon="bi bi-plus-circle" outlined
                  :disabled="!selectedInstituteAddress.new" @click="openFunctionEditModal"/>
        </InputGroup>
        <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!instituteAddressDataValid.Institute_Function_Code">{{ $t('institutes.functions.selectFunction') }}</PrimeMessage>
      </div>
      <div class="form-row mb-3">
        <label for="memberId" class="form-label">{{ $t('institutes.functions.selectMember') }}</label>
        <Select input-id="memberId" v-model="selectedInstituteAddress.Contact_No"
                :tabindex="2"
                :options="clubMemberList"
                option-value="memberId"
                :option-label="getMemberName"
                checkmark
                filter
                :disabled="!selectedInstituteAddress.new"
                :placeholder="$t('institutes.functions.selectMember')"
                :invalid="!selectedInstituteAddress.Contact_No"
                class="w-100"
                @keyup.enter="saveInstituteAddress">
        </Select>
        <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!instituteAddressDataValid.Contact_No">{{ $t('institutes.functions.selectMember') }}</PrimeMessage>
      </div>
      <div class="form-row mb-3">
        <label for="startDate" class="form-label">{{ $t('section.education.startDate') }}</label>
        <DatePicker input-id="startDate" :tabindex="3" v-model="selectedInstituteAddress.Start_date" :model-value="selectedInstituteAddress.Start_date"
                    show-button-bar showIcon class="w-100" @keyup.enter="saveInstituteAddress"/>
      </div>
      <div class="form-row mb-3">
        <label for="endDate" class="form-label">{{ $t('section.education.endDate') }}</label>
        <DatePicker input-id="endDate" :tabindex="4" v-model="selectedInstituteAddress.End_Date" :model-value="selectedInstituteAddress.End_Date"
                    show-button-bar showIcon class="w-100" @keyup.enter="saveInstituteAddress"/>
      </div>

      <div class="alert alert-info">
        {{ $t('institutes.functions.infotext') }}
      </div>
      <div class="d-flex justify-content-between">
        <Button :title="$t('save')" :label="$t('save')"
                :tabindex="5"
                unstyled class="btn btn-outline-primary"
                :disabled="!newInstituteAddressValid"
                @click="saveInstituteAddress"/>
        <Button :title="$t('cancel')" :label="$t('cancel')" :tabindex="6" unstyled class="btn btn-outline-secondary" @click="showInstituteAddressModal = false"/>
      </div>
    </Dialog>

    <Dialog v-model:visible="showCreateFunctionModal" modal block-scroll :header="$t('institutes.functions.create')" :style="{width: '30rem'}" @hide="resetFunctionCreation">
      <div class="form-row mb-3" v-focustrap>
        <label for="functionName" class="form-label">{{ $t('institutes.functions.name') }}</label>
        <InputText input-id="functionName" tabindex="1" autofocus v-model="newFunctionData.Description" :invalid="!newFunctionDataValid.Description" :placeholder="$t('institutes.functions.name')"
                   @update:modelValue="analyzeNewFunctionData" @keyup.enter="saveNewFunction" class="w-100"/>
        <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!newFunctionDataValid.Description" class="mt-2">{{ $t('institutes.functions.validation.noDescription') }}</PrimeMessage>
      </div>
      <div class="form-row mb-3">
        <label for="functionStep" class="form-label">{{ $t('institutes.functions.step') }}</label>
        <InputText input-id="functionStep" tabindex="2" v-model="newFunctionData.Step" :invalid="!newFunctionDataValid.Step" :placeholder="$t('institutes.functions.step')" @update:modelValue="analyzeNewFunctionData" @keyup.enter="saveNewFunction" class="w-100"/>
        <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!newFunctionDataValid.Step" class="mt-2">{{ $t('institutes.functions.validation.noStep') }}</PrimeMessage>
      </div>
      <div class="d-flex justify-content-between">
        <Button :title="$t('institutes.functions.create')"
                :label="$t('institutes.functions.create')"
                tabindex="3"
                unstyled class="btn btn-outline-primary"
                :disabled="!allTrue(newFunctionDataValid) || isSubmitted"
                @click="saveNewFunction"
                @keyup.enter="saveNewFunction"/>
        <Button :title="$t('cancel')" :label="$t('cancel')" tabindex="4" unstyled class="btn btn-outline-secondary" @click="showCreateFunctionModal = false"/>
      </div>
    </Dialog>
  </div>
</template>
<script setup>

import PrimeMessage from "primevue/message";
import Dialog from "primevue/dialog";
import InputGroup from "primevue/inputgroup";
import InputText from "primevue/inputtext";
import Select from "primevue/select";
import DatePicker from "primevue/datepicker";
import Button from "primevue/button";


import {computed, ref} from "vue";
import {useClubStore} from "@/store/modules/club/club";
import {useInstituteStore} from "@/store/modules/institute/institute";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import {useI18n} from "vue-i18n";
import {useConfirm} from "primevue/useconfirm";
import moment from "moment";
import {useRouter} from "vue-router";
import {FilterMatchMode} from "@primevue/core/api";
import {ASSOC_SHOW_GROUP_FUNCTIONS, ASSOC_SHOW_GROUPS} from "@/router/urls_association";

const {t} = useI18n();
const instituteStore = useInstituteStore();
const clubStore = useClubStore();
const messageStore = useMessagesStore();
const confirm = useConfirm();
const router = useRouter();

const EMPTY_DATE = '0001-01-01T00:00:00';

const props = defineProps({
  clubId: {
    type: String,
    required: true,
  },
  code: {
    type: String,
    required: true,
  },
  isAssociation: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const groupRoute = computed(() => {
  if (props.isAssociation) {
    return {name: ASSOC_SHOW_GROUPS.name, query: {associationId: props.clubId}};
  } else {
    return {name: 'club-groups', query: {clubId: props.clubId}};
  }
});

const functionRoute = computed(() => {
  if (props.isAssociation) {
    return {name: ASSOC_SHOW_GROUP_FUNCTIONS.name, query: {associationId: props.clubId, code: props.code}}
  } else {
    return {name: 'institute-functions', query: {clubId: props.clubId, code: props.code}};
  }
});

const showInstituteAddressModal = ref(false);
const showCreateFunctionModal = ref(false);

const isSubmitted = ref(false);
const showSuccess = ref(false);
const showFailure = ref(false);
const isModalLoading = ref(false);

const dt = ref();
const sortColumn = ref('instituteFunctionCode');
const columns = [
  {field: 'instituteFunctionCode', header: t('institutes.list.code'), filterable: false, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'functionDescription', header: t('institutes.functions.name'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'step', header: t('institutes.functions.step'), filterable: false, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'surname', header: t('personalData.person.surname'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'firstName', header: t('personalData.person.firstName'), filterable: true, exportable: true, visible: true, sortable: true, type: 'String'},
  {field: 'birthday', header: t('personalData.person.birthday'), filterable: true, exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'startDate', header: t('section.education.startDate'), filterable: true, exportable: true, visible: true, sortable: true, type: 'Date'},
  {field: 'endDate', header: t('section.education.endDate'), filterable: true, exportable: true, visible: true, sortable: true, type: 'Date'},
];
const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => a[b], obj);
};

const filters = ref({
  'instituteFunctionCode': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'functionDescription': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'surname': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'firstName': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'birthday': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'startDate': {value: null, matchMode: FilterMatchMode.CONTAINS},
  'endDate': {value: null, matchMode: FilterMatchMode.CONTAINS},
});

const csvExportTransformationFunction = (record) => {
  switch (record.field) {
    case 'birthday':
    case 'startDate':
    case 'endDate':
      return formatEntry('Date', record.data);
    default:
      return String(record.data)
  }
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Salutation':
      return t('club.member.salutation' + value);
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

const newFunctionValid = computed(() => {
  return allTrue(newFunctionDataValid.value);
});

const clubData = computed(() => {
  return clubStore.getClubData;
});

const institute = computed(() => {
  return instituteStore.getInstituteData;
});

const instituteFunctionList = computed(() => {
  return instituteStore.getInstituteFunctions;
});

const clubMemberList = computed(() => {
  return clubStore.getClubMembers;
});

const instituteAddressList = computed(() => {
  return instituteStore.getInstituteAddresses;
});

const exportCSV = () => {
  dt.value.exportCSV();
};

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const getMemberName = (member) => {
  return member.firstName + ' ' + member.surname;
};

const newFunctionDataValid = ref({
  Description: false,
  Step: true,
});

const selectedInstituteAddress = ref({
  Club_No: props.clubId,
  Institute_Code: props.code,
  Contact_No: '',
  Entry_No: '',
  Institute_Function_Code: '',
  Start_date: null,
  End_Date: null,
  new: true,
});

const instituteAddressDataValid = ref({
  Club_No: false,
  Institute_Code: false,
  Contact_No: false,
  Institute_Function_Code: false,
});

const newInstituteAddressValid = computed(() => {
  checkInstituteAddressProperties(selectedInstituteAddress);
  return allTrue(instituteAddressDataValid.value);
});

const checkInstituteAddressProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'Club_No' || key === 'Institute_Code' || key === 'Contact_No' || key === 'Institute_Function_Code') {
      instituteAddressDataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20)
    }
  }
};

const newFunctionData = ref({
  Club_No: clubData.value.data.No,
  Description: '',
  Step: '',
  new: true,
});

const openFunctionEditModal = async () => {
  showInstituteAddressModal.value = false;
  showCreateFunctionModal.value = true;
};

const showInstituteAddressNewDialog = async () => {
  isModalLoading.value = true;
  if (clubMemberList.value.length === 0) {
    await clubStore.loadClubMembers(clubData.value.data.No, false);
  }
  showInstituteAddressModal.value = true;
  isModalLoading.value = false;
};

const showInstituteAddressEditDialog = async (instituteAddress) => {
  selectedInstituteAddress.value = {
    Club_No: instituteAddress.clubNo,
    Institute_Code: instituteAddress.instituteCode,
    Entry_No: instituteAddress.entryNo,
    Contact_No: instituteAddress.memberId,
    Institute_Function_Code: instituteAddress.instituteFunctionCode,
    Start_date: instituteAddress.startDate === null ? null : new Date(instituteAddress.startDate),
    End_Date: instituteAddress.endDate === null ? null : new Date(instituteAddress.endDate),
    new: false,
    oldInstituteAddress: instituteAddress,
  };

  checkInstituteAddressProperties(selectedInstituteAddress);
  showInstituteAddressModal.value = true;

};

const resetInstituteAddress = () => {
  selectedInstituteAddress.value = {
    Club_No: router.currentRoute.value.query.clubId,
    Institute_Code: router.currentRoute.value.query.code,
    Entry_No: '',
    Contact_No: '',
    Institute_Function_Code: '',
    Start_date: null,
    End_Date: null,
    new: true,
    oldInstituteAddress: {},
  };
};

const saveInstituteAddress = async () => {
  isSubmitted.value = true;
  showSuccess.value = false;
  showFailure.value = false;
  if (selectedInstituteAddress.value.startDate === null) {
    selectedInstituteAddress.value.startDate = EMPTY_DATE;
  }
  if (selectedInstituteAddress.value.endDate === null) {
    selectedInstituteAddress.value.endDate = EMPTY_DATE;
  }

  instituteStore.saveOrUpdateInstituteAddress(selectedInstituteAddress.value).then(response => {
    if (response.status >= 200 && response.status < 300) {
      showSuccess.value = true;
      messageStore.addMessage(new Message('info', true, false, t('institutes.functions.add'), '', t('institutes.functions.save.success'), false, 'COMPONENT'));
    } else {
      showFailure.value = true;
      if (response.data.errors) {
        let combinedMessage = '';
        response.data.errors.forEach(e => {
          combinedMessage += (t(e) + '\r\n');
        });
        messageStore.addError(new Message('error', true, true, t('institutes.functions.add'), '', combinedMessage, false, 'COMPONENT'));
      } else {
        messageStore.addError(new Message('error', true, true, t('institutes.functions.add'), '', response.data, false, 'COMPONENT'));
      }
    }
  }).catch(err => {
    console.error(err);
    showFailure.value = true;
    messageStore.addError(new Message('error', true, false, t('institutes.functions.add'), '', err, false, 'COMPONENT'));
  }).finally(async () => {
    isSubmitted.value = false;
    showInstituteAddressModal.value = false;
    await reloadInstituteFunctions(selectedInstituteAddress.value);
  });
};

const confirmDelete = (event, instituteAddress) => {
  confirm.require({
    target: event.currentTarget,
    header: t('institutes.members.delete.label'),
    message: t('institutes.members.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    accept: () => {
      deleteInstituteAddress(instituteAddress);
    },
  });
};

const deleteInstituteAddress = (instituteAddress) => {
  instituteStore.deleteInstituteAddress(instituteAddress.entryNo).then(r => {
    if (r.status >= 200 && r.status < 300) {
      showSuccess.value = true;
      messageStore.addInfo(new Message('info', true, false, t('institutes.members.delete.confirm'), '', t('institutes.members.delete.success', {member: instituteAddress.firstName + ' ' + instituteAddress.surname}), true, 'COMPONENT'));
    } else {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', r.data, true, 'COMPONENT'));
    }
  }).catch(e => {
    console.error(e);
    messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', e, true, 'COMPONENT'));
  }).finally(() => {
    reloadInstituteFunctions(selectedInstituteAddress.value);
  });
};

const resetFunctionCreation = () => {
  newFunctionData.value = {
    Club_No: clubData.value.data.No,
    Description: '',
    Step: '',
    new: true,
  };
  showInstituteAddressModal.value = true;
};

const saveNewFunction = () => {
  if (newFunctionValid.value) {
    isSubmitted.value = true;
    showSuccess.value = false;
    showFailure.value = false;
    createFunction().then(async response => {
      if (response?.status === 200) {
        showSuccess.value = true;
      } else {
        showFailure.value = true;
      }

      if (showSuccess.value) {
        messageStore.addInfo(new Message('info', true, false, t('institutes.functions.add'), '', t('institutes.functions.save.success'), false, 'COMPONENT'));
        selectedInstituteAddress.value.Institute_Function_Code = response.data.Code;
        await reloadFunctions();
        isSubmitted.value = false;
        showCreateFunctionModal.value = false;
      } else if (response?.response.data.errors) {
        let combinedMessage = '';
        response.response.data.errors.forEach(e => {
          combinedMessage += (t(e) + '\r\n');
        });
        messageStore.addError(new Message('error', true, false, t('institutes.functions.add'), '', combinedMessage, false, 'COMPONENT'));
      }
    }).catch(err => {
      showFailure.value = true;
      console.error(err);
    }).finally(() => {
      isSubmitted.value = false;
    });
  }
};

const createFunction = async () => {
  return instituteStore.saveOrUpdateInstituteFunction(newFunctionData.value).then(response => {
    return response;
  });
};

const reloadInstituteFunctions = async (instituteData) => {
  await instituteStore.loadInstitueAddressesForClub(instituteData.Club_No, instituteData.Institute_Code);
};

const reloadFunctions = async () => {
  await instituteStore.loadInstituteFunctionsForClub(clubData.value.data.No);
};

const analyzeNewFunctionData = () => {
  for (const key in newFunctionData.value) {
    if (key === 'Description') {
      newFunctionDataValid.value[key] = isNotEmptyWithinMaxLength(newFunctionData.value[key], 50);
    } else if (key === 'Step') {
      newFunctionDataValid.value[key] = isEmptyOrWithinMaxLength(newFunctionData.value[key], 10);
    }
  }
};

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return !!(value && value !== '' && value.length < maxLength);
}

const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return !value || (value === '' || (value.length <= maxLength));
};


const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
};

</script>
