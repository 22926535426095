<template>
  <association-layout :title="clubData.status === 200 ? $t('institutes.functions.title') + ' ' + clubData.data.Name : $t('club.notFound.title')" :is-loading="!functionList?.data">
    <template #content>
      <div class="row">
        <div class="col">
          <h5 class="mb-3">{{ $t('institutes.functions.manage') }}</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <DataTable :value="functionList.data"
                     scrollable scroll-height="60vh"
                     :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                     data-key="Code"
                     v-model:filters="filters" filter-display="row"
                     :loading="!functionList?.data"
                     ref="dt" :export-filename="$t('institutes.functions.title').replaceAll(' ', '_')"
                     :paginator="functionList.data.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                     class="border-bottom">
            <template #empty>
              <div class="alert alert-info">{{ $t('institutes.addresses.list.noData') }}</div>
            </template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column>
              <template #body="{data}">
                <div class="btn-group btn-group-sm">
                  <Button :title="$t('institutes.functions.edit', {functionName: data.Description})"
                          unstyled class="btn btn-outline-secondary" @click="showEditFunctionModal(data)">
                    <i class="bi bi-pencil"></i>
                  </Button>
                  <Button :title="$t('institutes.functions.delete.label')"
                          unstyled class="btn btn-outline-primary" @click="confirmDelete($event, data)">
                    <i class="bi bi-trash"></i>
                  </Button>
                </div>
              </template>
            </Column>

            <Column v-for="col in columns" :key="col.field" :field="col.field"
                    :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                    :header="col.header"
                    sortable :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    :exportable="col.exportable"
                    :hidden="!col.visible">
              <template #body="{data}">
                {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
              </template>
              <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
              </template>
            </Column>

          </DataTable>
        </div>
      </div>

      <div class="row w-100 flex-center gap-2 mb-3 mt-3">
        <router-link :to="{name: ASSOC_SHOW_GROUP_MEMBERS.name, query: {associationId: clubData.data.No, code: $route.query.code}}" class="btn btn-outline-secondary width-auto">
          <i class="bi bi-arrow-left me-2"></i><span class="p-button-label">{{ $t('back') }}</span>
        </router-link>
        <Button title="Export" label="Export" @click="exportCSV($event)" unstyled class="btn btn-outline-primary datatable-export width-auto">
          <i class="bi bi-filetype-csv"></i>
        </Button>
        <Button :title="$t('institutes.functions.create')" :label="$t('institutes.functions.create')"
                unstyled class="btn btn-outline-secondary width-auto"
                icon="bi bi-plus-circle me-2"
                @click="showEditFunctionModal(selectedInstituteFunction)"/>
      </div>

    </template>
  </association-layout>

  <Dialog v-model:visible="showCreateFunctionModal" modal block-scroll :header="selectedInstituteFunction.new ? $t('institutes.functions.create') : $t('institutes.functions.edit')" :style="{width: '25rem'}" @hide="resetFunctionCreation">
    <div class="form-row mb-3" v-focustrap>
      <label for="functionName" class="form-label">{{ $t('institutes.functions.name') }}</label>
      <InputText input-id="functionName" :tabindex="1" autofocus v-model="selectedInstituteFunction.Description" :invalid="!newFunctionDataValid.Description" :placeholder="$t('institutes.functions.name')" @update:modelValue="analyzeNewFunctionData" class="w-100"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!newFunctionDataValid.Description" class="mt-2">{{ $t('institutes.validation.nameEmptyOrExceeded') }}</PrimeMessage>
    </div>
    <div class="form-row mb-3">
      <label for="functionStep" class="form-label">{{ $t('institutes.functions.step') }}</label>
      <InputText input-id="functionStep" :tabindex="2" v-model="selectedInstituteFunction.Step" :placeholder="$t('institutes.functions.step')" @update:modelValue="analyzeNewFunctionData" class="w-100"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!newFunctionDataValid.Step" class="mt-2">{{ $t('institutes.validation.stepExceeded') }}</PrimeMessage>
    </div>
    <div class="d-flex justify-content-between">
      <Button :title="$t('save')"
              :label="$t('save')"
              :tabindex="3"
              unstyled
              class="btn btn-outline-primary"
              :disabled="!allTrue(newFunctionDataValid) || isSubmitted"
              @click="saveNewFunction"
              @keyup.enter="saveNewFunction"/>
      <Button :title="$t('cancel')" :label="$t('cancel')" :tabindex="4" unstyled class="btn btn-outline-secondary" @click="showCreateFunctionModal = false"/>
    </div>
  </Dialog>

  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import {useClubStore} from "@/store/modules/club/club";
import {computed, ref} from "vue";
import {useInstituteStore} from "@/store/modules/institute/institute";
import {FilterMatchMode} from "@primevue/core/api";
import {useConfirm} from "primevue/useconfirm";
import {useI18n} from "vue-i18n";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import Dialog from "primevue/dialog";
import PrimeMessage from "primevue/message";
import InputText from "primevue/inputtext";
import moment from "moment";
import {ASSOC_SHOW_GROUP_MEMBERS} from "@/router/urls_association";

const {t} = useI18n();
const clubStore = useClubStore();
const instituteStore = useInstituteStore();
const confirm = useConfirm();
const messageStore = useMessagesStore();

const showSuccess = ref(false);
const showFailure = ref(false);
const isSubmitted = ref(false);
const showCreateFunctionModal = ref(false);

const dt = ref();
const sortColumn = ref('Step');
const columns = [
  {field: 'Code', header: t('institutes.list.code'), filterable: true, exportable: true, visible: true, type: 'String'},
  {field: 'Step', header: t('institutes.functions.step'), filterable: false, exportable: true, visible: true, type: 'String'},
  {field: 'Description', header: t('institutes.functions.name'), filterable: true, exportable: true, visible: true, type: 'String'},
];
const filters = ref({
  Code: {value: null, matchMode: FilterMatchMode.CONTAINS},
  Description: {value: null, matchMode: FilterMatchMode.CONTAINS},
  Step: {value: null, matchMode: FilterMatchMode.CONTAINS},
})

const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => a[b], obj);
};

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Salutation':
      return t('club.member.salutation' + value);
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

const clubData = computed(() => {
  return clubStore.getClubData;
});

const functionList = computed(() => {
  return instituteStore.getInstituteFunctions;
});

const exportCSV = () => {
  dt.value.exportCSV();
};

const selectedInstituteFunction = ref({
  Club_No: clubData.value.data.No,
  Description: '',
  Step: '',
  new: true,
});

const resetFunctionCreation = () => {
  selectedInstituteFunction.value = {
    Club_No: clubData.value.data.No,
    Description: '',
    Step: '',
    new: true,
  };
  reloadInstituteFunctions(clubData.value.data.No);
};

const newFunctionDataValid = ref({
  Description: false,
  Step: false,
});

const newFunctionValid = computed(() => {
  analyzeNewFunctionData();
  return allTrue(newFunctionDataValid.value);
});

const showEditFunctionModal = (instituteFunction) => {
  selectedInstituteFunction.value = JSON.parse(JSON.stringify(instituteFunction))
  analyzeNewFunctionData();
  showCreateFunctionModal.value = true;
};

const analyzeNewFunctionData = () => {
  for (const key in selectedInstituteFunction.value) {
    if (key === 'Description') {
      newFunctionDataValid.value[key] = isNotEmptyWithinMaxLength(selectedInstituteFunction.value[key], 50);
    } else if (key === 'Step') {
      newFunctionDataValid.value[key] = isNotEmptyWithinMaxLength(selectedInstituteFunction.value[key], 10);
    }
  }
};

const saveNewFunction = () => {
  if (newFunctionValid.value) {
    isSubmitted.value = true;
    showSuccess.value = false;
    showFailure.value = false;
    instituteStore.saveOrUpdateInstituteFunction(selectedInstituteFunction.value).then(async response => {
      if (response && response.status === 200) {
        showSuccess.value = true;
      } else {
        showFailure.value = true;
      }

      if (showSuccess.value) {
        messageStore.addInfo(new Message('info', true, false, t('institutes.functions.add'), '', t('institutes.functions.save.success'), false, 'COMPONENT'));
        isSubmitted.value = false;
      } else {
        if (response.data.errors) {
          let combinedMessage = '';
          response.data.errors.forEach(e => {
            combinedMessage += (t(e) + '\r\n');
          });
          messageStore.addError(new Message('error', true, false, t('institutes.functions.add'), '', combinedMessage, false, 'COMPONENT'));
        } else {
          messageStore.addError(new Message('error', true, false, t('institutes.functions.add'), '', t(response.data), false, 'COMPONENT'));
        }
      }
    }).catch(err => {
      console.error(err);
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('institutes.functions.add'), '', err, false, 'COMPONENT'));
    }).finally(() => {
      isSubmitted.value = false;
      showCreateFunctionModal.value = false;
      reloadInstituteFunctions(selectedInstituteFunction.value.Club_No);
    });
  }
};

const confirmDelete = (event, instituteFunctionData) => {
  confirm.require({
    target: event.currentTarget,
    header: t('institutes.functions.delete.label'),
    message: t('institutes.functions.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    acceptProps: {
      label: t('yes'),
      unstyled: true,
      class: 'btn btn-outline-danger',
    },
    accept: () => {
      deleteInstituteFunction(instituteFunctionData);
    },
  });
};

const deleteInstituteFunction = async (instituteFunction) => {
  isSubmitted.value = true;
  instituteStore.deleteInstituteFunction(instituteFunction).then(r => {
    if (r.status >= 200 && r.status < 300) {
      showSuccess.value = true;
      messageStore.addInfo(new Message('info', true, false, t('institutes.functions.delete.confirm'), '', t('institutes.functions.delete.success', {functionName: instituteFunction.Description}), true, 'COMPONENT'));
    } else {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', r.data, true, 'COMPONENT'));
    }
  }).catch(e => {
    console.error('ERROR', e);
    messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', e, true, 'COMPONENT'));
  }).finally(async () => {
    await reloadInstituteFunctions(instituteFunction.Club_No);
  });
};

const reloadInstituteFunctions = async (clubNo) => {
  await instituteStore.loadInstituteFunctionsForClub(clubNo)
};

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
};

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return value && value !== '' && value.length < maxLength;
}

</script>
