<template>
  <association-layout :title="currentClubData.status === 200 ? $t('association.association') + ' ' + clubBaseData.Name : $t('association.notFound.title')" :is-loading="isSubmitted">
    <template #content v-if="currentClubData.status === 200">
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <div class="row border-bottom pb-4 mb-4" >
            <div class="col d-flex align-items-center gap-4">
              <VereinAvatar avatar-class="profile img-fluid" v-if="currentClubLogo">
                <button type="button" class="img-profile-overlay" data-bs-toggle="modal" data-bs-target="#imageCropperVereinModal">
              <span>
                <span class="display-3"><i class="bi bi-camera"></i></span><br>
                {{ $t('association.changePicture') }}
              </span>
                </button>
              </VereinAvatar>
              <h6>
                {{clubBaseData.Name}}
                <button type="button"
                        class="btn btn-outline-secondary d-block mt-3"
                        data-bs-toggle="modal" data-bs-target="#imageCropperVereinModal">{{ currentClubLogo ? $t('association.changePicture') : $t('association.uploadNewPicture') }}
                </button>
              </h6>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.nr') }}</strong></p>
              <p>
                {{ clubBaseData.No }}<br>
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.stvName') }}</strong></p>
              <p class="text-muted"><em>{{ $t('association.stvNameInfo') }}</em></p>
              <p>
                {{ clubBaseData.Name }}<br>
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.name') }} *</strong></p>
              <p class="text-muted"><em>{{ $t('association.nameInfo') }}</em></p>
              <div class="form-floating has-validation">
                <input type="text" id="display-name" class="form-control"
                       :class="{'is-invalid': !dataValid.DisplayName, 'is-valid' : isNotEmptyWithinMaxLength(clubBaseData.DisplayName, maxLengthInput)}"
                       :placeholder="$t('association.name')" v-model="clubBaseData.DisplayName"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="display-name" class="form-label">{{ $t('association.name') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.DisplayName">{{ $t('association.validation.emptyOrExceeded.displayName', {maxLength: maxLengthInput}) }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.foundingYear') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="display-name" class="form-control"
                       :class="{'is-invalid': !dataValid.Year_Established, 'is-valid': isNotEmptyWithinMinAndMaxLength(clubBaseData.Year_Established, minLengthYearInput, maxLengthYearInput)}"
                       :placeholder="$t('association.foundingYear')" required="true"
                       v-model="clubBaseData.Year_Established" @keyup="analyzeBaseData" @keyup.enter="submitBaseData"
                       inputmode="numeric" pattern="\d*" maxlength="4">
                <label for="display-name" class="form-label">{{ $t('association.foundingYear') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.Year_Established">{{ $t('association.validation.invalidYear') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <h5 class="mb-3">{{ $t('association.communication.title') }}</h5>
          <p class="text-muted"><em>{{ $t('association.communication.info') }}</em></p>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.communication.email') }}</strong></p>
              <div class="form-floating has-validation">
                <MailInput v-model.lowercase="clubBaseData.E_Mail" :label="$t('association.communication.email')" id="club-email" @on-change="onMailChange"></MailInput>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.communication.homepage') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="display-name" class="form-control"
                       :class="{'is-invalid': !dataValid.Home_Page, 'is-valid' : isNotEmptyWithinMaxLength(clubBaseData.Home_Page, maxLengthInput)}"
                       :placeholder="$t('association.communication.homepage')" v-model="clubBaseData.Home_Page"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="display-name" class="form-label">{{ $t('association.communication.homepage') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.Home_Page">{{ $t('association.validation.invalidLength.homepage', {maxLength: maxLengthInput}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.communication.instagram') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="display-name" class="form-control"
                       :class="{'is-invalid': !dataValid.Instagram, 'is-valid' : isNotEmptyWithinMaxLength(clubBaseData.Instagram, maxLengthInput)}"
                       :placeholder="$t('association.communication.instagram')" v-model="clubBaseData.Instagram"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="display-name" class="form-label">{{ $t('association.communication.instagram') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.Instagram">{{ $t('association.validation.invalidLength.instagram', {maxLength: maxLengthInput}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.communication.facebook') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="display-name" class="form-control"
                       :class="{'is-invalid': !dataValid.Facebook, 'is-valid' : isNotEmptyWithinMaxLength(clubBaseData.Facebook, maxLengthInput)}"
                       :placeholder="$t('association.communication.facebook')" v-model="clubBaseData.Facebook"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="display-name" class="form-label">{{ $t('association.communication.facebook') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.Facebook">{{ $t('association.validation.invalidLength.facebook', {maxLength: maxLengthInput}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.communication.tikTok') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="display-name" class="form-control"
                       :class="{'is-invalid': !dataValid.TikTok, 'is-valid' : isNotEmptyWithinMaxLength(clubBaseData.TikTok, maxLengthInput)}"
                       :placeholder="$t('association.communication.tikTok')" v-model="clubBaseData.TikTok"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="display-name" class="form-label">{{ $t('association.communication.tikTok') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.TikTok">{{ $t('association.validation.invalidLength.tikTok', {maxLength: maxLengthInput}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.communication.linkedIn') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="display-name" class="form-control"
                       :class="{'is-invalid': !dataValid.LinkedIn, 'is-valid' : isNotEmptyWithinMaxLength(clubBaseData.LinkedIn, maxLengthInput)}"
                       :placeholder="$t('association.communication.instagram')" v-model="clubBaseData.LinkedIn"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="display-name" class="form-label">{{ $t('association.communication.linkedIn') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.LinkedIn">{{ $t('association.validation.invalidLength.linkedIn', {maxLength: maxLengthInput}) }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('association.communication.x') }}</strong></p>
              <div class="form-floating has-validation">
                <input type="text" id="display-name" class="form-control"
                       :class="{'is-invalid': !dataValid.X, 'is-valid' : isNotEmptyWithinMaxLength(clubBaseData.X, maxLengthInput)}"
                       :placeholder="$t('association.communication.x')" v-model="clubBaseData.X"
                       @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="display-name" class="form-label">{{ $t('association.communication.x') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.X">{{ $t('association.validation.invalidLength.x', {maxLength: maxLengthInput}) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="d-flex gap-2">
          <DefaultButton
            class="btn-outline-primary"
            role="button"
            :label="$t('association.save.data')"
            @click="submitBaseData"
            @keyup.enter="submitBaseData"
            :disabled="(!allDataValid || isSubmitted) || !dataChanged"
          />
          <DefaultButton
            class="btn-outline-secondary"
            role="button"
            :label="$t('cancel')"
            @click="resetData"
            @keyup.enter="resetData"
            :disabled="(!allDataValid || isSubmitted) || !dataChanged"
          />
        </div>
      </div>
      <BootstrapToast />
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </association-layout>
  <VereinLogoModal :is-association="true" :clubId="clubBaseData.No" @toast-show="showToastMessage" />

</template>

<script setup>
import {computed, onBeforeMount, onMounted, ref} from 'vue'
import {useClubStore} from "@/store/modules/club/club.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n'
import MailInput from "@/components/ui/MailInput.vue";
import Message from "@/helpers/message.js";
import BootstrapToast from '@/components/ui/BootstrapToast.vue';
import VereinLogoModal from '@/components/ui/VereinLogoModal.vue';
import VereinAvatar from '@/components/personal-data/VereinAvatar.vue';
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import AssociationLayout from "@/components/association/AssociationLayout.vue";

const router = useRouter();
const clubStore = useClubStore();
const messageStore = useMessagesStore();
const minLengthYearInput = 4;
const maxLengthYearInput = 4;
const maxLengthInput = 80;
const currentClubData = computed(() => clubStore.getClubData);
const currentClubLogo = computed(() => clubStore.getClubLogo)
const { t } = useI18n()


let showFailure = ref(false);
let showSuccess = ref(false);
let isSubmitted = ref(false);
// eslint-disable-next-line no-unused-vars
let clubBaseData = ref({
  No: currentClubData.value.data.No,
  Name: currentClubData.value.data.Name,
  DisplayName: currentClubData.value.data.DisplayName,
  Year_Established: currentClubData.value.data.Year_Established,
  Home_Page: currentClubData.value.data.Home_Page,
  E_Mail: currentClubData.value.data.E_Mail,
  Instagram: currentClubData.value.data.Instagram,
  Facebook: currentClubData.value.data.Facebook,
  TikTok: currentClubData.value.data.TikTok,
  LinkedIn: currentClubData.value.data.LinkedIn,
  X: currentClubData.value.data.X,
})
let dataValid = ref({
  DisplayName: false,
  Year_Established: false,
  E_Mail: false,
  Home_Page: false,
  Instagram: false,
  Facebook: false,
  TikTok: false,
  LinkedIn: false,
  X: false
})

const dataChanged = computed(() => {
  return (clubBaseData.value.DisplayName !== currentClubData.value.data.DisplayName)
    || (clubBaseData.value.Year_Established !== currentClubData.value.data.Year_Established)
    || (clubBaseData.value.E_Mail !== currentClubData.value.data.E_Mail)
    || (clubBaseData.value.Home_Page !== currentClubData.value.data.Home_Page)
    || (clubBaseData.value.Instagram !== currentClubData.value.data.Instagram)
    || (clubBaseData.value.Facebook !== currentClubData.value.data.Facebook)
    || (clubBaseData.value.TikTok !== currentClubData.value.data.TikTok)
    || (clubBaseData.value.LinkedIn !== currentClubData.value.data.LinkedIn)
    || (clubBaseData.value.X !== currentClubData.value.data.X);
})

const allDataValid = computed(() => {
  return allTrue(dataValid.value);
})

const analyzeBaseData = () =>  {
  checkProperties(clubBaseData);
  isSubmitted.value = false;
}

const resetData = () => {
  clubBaseData.value.No = currentClubData.value.data.No;
  clubBaseData.value.Name = currentClubData.value.data.Name;
  clubBaseData.value.DisplayName = currentClubData.value.data.DisplayName;
  clubBaseData.value.Year_Established = currentClubData.value.data.Year_Established;
  clubBaseData.value.Home_Page = currentClubData.value.data.Home_Page;
  clubBaseData.value.E_Mail = currentClubData.value.data.E_Mail;
  clubBaseData.value.Instagram = currentClubData.value.data.Instagram;
  clubBaseData.value.Facebook = currentClubData.value.data.Facebook;
  clubBaseData.value.TikTok = currentClubData.value.data.TikTok;
  clubBaseData.value.LinkedIn = currentClubData.value.data.LinkedIn;
  clubBaseData.value.X = currentClubData.value.data.X;
  analyzeBaseData();
}

const submitBaseData = () => {
  if (allDataValid.value && dataChanged) {
    isSubmitted.value = true;
    saveClubData().then(async response => {
      if (response && response.status === 200) {
        showSuccess.value = true;
        await reloadClubData(clubBaseData.value.No);
      } else {
        showFailure.value = true;
      }

      if(showSuccess.value) {
        messageStore.addInfo(new Message('info', true, false, t('association.profile'), '', t('association.save.success'), true, 'COMPONENT'));
        isSubmitted.value = false;
        router.push({
          name: 'association-profile',
          query: {associationId: clubBaseData.value.No}
        });
      } else {
        messageStore.addError(new Message('error', true, false, t('association.profile'), '', t('association.save.failure'), true, 'COMPONENT'));
      }
    }).catch(err => {
      showFailure.value = true;
      console.log(err);
    }).finally(() => {
      isSubmitted.value = false;
      window.scrollTo(0, 0);
    });
  }
}

const saveClubData = async () => {
  return clubStore.saveClubBaseData(clubBaseData.value).then(response => {
    return response;
  });
}

const reloadClubData = async (clubNumber) => {
  return clubStore.loadClubData(clubNumber);
}

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'Year_Established') {
      dataValid.value[key] = isEmptyOrWithinMinAndMaxLength(obj.value[key], minLengthYearInput, maxLengthYearInput);
    } else if(key === 'DisplayName') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], maxLengthInput);
    } else {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], maxLengthInput);
    }
  }
}

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
}

const onMailChange = (mailValidationState) => {
  dataValid.value.E_Mail = mailValidationState;
}

const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return !value || (value === '' || value.length <= maxLength);
}

const isEmptyOrWithinMinAndMaxLength = (value, minLength, maxLength) => {
  return !value || (value === '' || (value.length <= maxLength && value.length >= minLength));
}

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return value && value !== '' && value.length <maxLength;
}

const isNotEmptyWithinMinAndMaxLength = (value, minLength, maxLength) => {
  return value && value !== '' && value.length <= maxLength && value.length >= minLength;
}

onBeforeMount(async () =>  {
  dataValid.value.DisplayName = isNotEmptyWithinMaxLength(clubBaseData.value.DisplayName, maxLengthInput);
  dataValid.value.Year_Established = isEmptyOrWithinMinAndMaxLength(clubBaseData.value.Year_Established, minLengthYearInput, maxLengthYearInput);
  dataValid.value.E_Mail = isEmptyOrWithinMaxLength(clubBaseData.value.E_Mail, maxLengthInput);
  dataValid.value.Home_Page = isEmptyOrWithinMaxLength(clubBaseData.value.Home_Page, maxLengthInput);
  dataValid.value.Instagram = isEmptyOrWithinMaxLength(clubBaseData.value.Instagram, maxLengthInput);
  dataValid.value.Facebook = isEmptyOrWithinMaxLength(clubBaseData.value.Facebook, maxLengthInput);
  dataValid.value.TikTok = isEmptyOrWithinMaxLength(clubBaseData.value.TikTok, maxLengthInput)
  dataValid.value.LinkedIn = isEmptyOrWithinMaxLength(clubBaseData.value.LinkedIn, maxLengthInput)
  dataValid.value.X= isEmptyOrWithinMaxLength(clubBaseData.value.X, maxLengthInput);

})

onMounted(() => {
  analyzeBaseData();
})




</script>

<style scoped>

</style>
