<template>
  <footer id="footer" class="mt-auto">
    <div class="footer-inner">
      <div class="main-logo">
        <a href="/" class="d-block text-muted text-decoration-none lh-1">
          <svg width="75" height="75">
            <use xlink:href="@/assets/img/stv-logos.svg#stv"/>
          </svg>
        </a>
      </div>
      <div class="platin-partner">
        <strong class="text-muted">{{ $t('footer.platinPartner') }}</strong>
        <ul class="list-unstyled">
          <li class="list-inline-item">
            <a :href="$t('links.partner.coop')" target="_blank">
              <svg width="100%" height="50">
                <use xlink:href="@/assets/img/stv-logos.svg#coop"/>
              </svg>
            </a>
          </li>
          <li class="list-inline-item">
            <a :href="$t('links.partner.concordia')" target="_blank">
              <svg width="100%" height="50" style="padding-bottom: .75rem;">
                <use xlink:href="@/assets/img/stv-logos.svg#concordia"/>
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <div class="gold-partner">
        <strong class="text-muted">{{ $t('footer.goldPartner') }}</strong>
        <ul class="list-unstyled">
          <li class="list-inline-item">
            <a :href="$t('links.partner.swica')" target="_blank">
              <svg width="100%" height="50">
                <use xlink:href="@/assets/img/stv-logos.svg#swica"/>
              </svg>
            </a>
          </li>
          <li class="list-inline-item">
            <a :href="$t('links.partner.ochsner')" target="_blank">
              <svg width="130" height="55">
                <use xlink:href="@/assets/img/stv-logos.svg#ochsner"/>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>

  </footer>

</template>

<script>
import {mapState} from "pinia";
import {useLoginStore} from "@/store/modules/login/login.js";

export default {
  computed: {
    ...mapState(useLoginStore, ["isLoggedIn"])
  },
}
</script>
