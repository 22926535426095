<template>
  <profile-layout :title="currentMemberData.firstName + ' ' + currentMemberData.surname + ' / ' + $t('profile.editProfile')" :is-loading="isSubmitted">
    <template #content>
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <h5 class="mb-3">{{ $t('profile.fullName') }}</h5>
          <div class="row">
            <div class="col-6">
              <div class="form-floating has-validation">
                <input type="text" id="firstName" class="form-control" :class="{'is-invalid': !dataValid.firstName}" :placeholder="$t('profile.firstName')" v-model="memberBaseData.firstName" @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="firstName" class="form-label">{{ $t('profile.firstName') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.firstName">{{ $t('profile.validation.invalidFirstName') }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating has-validation">
                <input type="text" id="lastName" class="form-control" :class="{'is-invalid': !dataValid.surname}" :placeholder="$t('profile.lastName')" v-model="memberBaseData.surname" @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="firstName" class="form-label">{{ $t('profile.lastName') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.surname">{{ $t('profile.validation.invalidLastName') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <h5 class="mb-3">{{ $t('profile.address') }}</h5>
          <div class="row mb-3">
            <div class="col">
              <div class="form-floating has-validation">
                <input type="text" id="street" class="form-control" :class="{'is-invalid': !dataValid.address}" :placeholder="$t('profile.street')" v-model="memberBaseData.address" @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="street" class="form-label">{{ $t('profile.street') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.address">{{ $t('profile.validation.invalidStreet') }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <div class="form-floating has-validation">
                <input type="text" id="mailBox" class="form-control" :class="{'is-invalid': !dataValid.address}" :placeholder="$t('profile.mailBox')" v-model="memberBaseData.addressAddition" @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="mailBox" class="form-label">{{ $t('profile.mailBox') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.addressAddition">{{ $t('profile.validation.invalidMailBox') }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-4">
              <div class="form-floating has-validation">
                <input type="text" class="form-control" :class="{'is-invalid': !dataValid.postCode}" id="zip" :placeholder="$t('profile.zip')" v-model="memberBaseData.postCode" @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="zip" class="form-label">{{ $t('profile.zip') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.postCode">{{ $t('profile.validation.invalidZip') }}</div>
              </div>
            </div>
            <div class="col-8">
              <div class="form-floating has-validation">
                <input type="text" class="form-control" :class="{'is-invalid': !dataValid.city}" id="place" :placeholder="$t('profile.place')" v-model="memberBaseData.city" @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="place" class="form-label">{{ $t('profile.place') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.city">{{ $t('profile.validation.invalidPlace') }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-floating has-validation">
                <select id="countryCode" class="form-select" :class="{'is-invalid': !dataValid.countryCode}" v-model="memberBaseData.countryCode" @change="analyzeBaseData" @keyup.enter="submitBaseData">
                  <option :value="null">{{ $t('select') }}</option>
                  <option v-for="country in countries" :key="country.code" :value="country.code">{{ $i18n.locale !== 'de' ? country.nameFr : country.nameDe }}</option>
                </select>
                <label for="countryCode" class="form-label">{{ $t('profile.country') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.countryCode">{{ $t('profile.validation.invalidCountry') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5 class="mb-3">{{ $t('profile.phone.title') }}</h5>
          <div class="row mb-3">
            <div class="col">
              <div class="form-floating has-validation">
                <input type="tel"
                       id="phone" class="form-control" :class="{'is-invalid': !dataValid.phonePrivate && phoneRequired}" :placeholder="$t('profile.phone.title')" name="phone" v-model="memberBaseData.phonePrivate" @keyup="analyzeBaseData" @keyup.enter="submitBaseData">
                <label for="phone" class="form-label">{{ $t('profile.phone.title') }}</label>
                <div class="invalid-feedback" v-show="!dataValid.phonePrivate && phoneRequired">{{ $t('profile.validation.invalidPhone') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-flex gap-2">
          <DefaultButton
            class="btn-outline-secondary"
            role="button"
            :label="$t('profile.saveProfile')"
            @click="submitBaseData"
            @keyup.enter="submitBaseData"
            :disabled="(!allDataValid || isSubmitted) || !dataChanged"
          />
        </div>
      </div>
      <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;">
        <div id="toastMessage" class="toast align-items-center text-white" :class="toastClass" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="d-flex">
            <div class="toast-body">{{ message }}</div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </template>
  </profile-layout>

</template>

<script>
import {mapActions, mapState} from "pinia";
import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {Toast} from "bootstrap";
import ProfileLayout from "@/components/profile/ProfileLayout";

export default {
  components: {
    ProfileLayout,
  },
  data() {
    return {
      memberBaseData: {},
      dataValid: {
        firstName: false,
        surname: false,
        address: false,
        addressAddition: true,
        postCode: false,
        city: false,
        phonePrivate: false,
        birthday: false,
        countryCode: false,
      },
      showSuccess: false,
      showFailure: false,
      avatarUploaded: false,
      isSubmitted: false,
      countries: null,
      phoneRequired: false,
      toast: null,
    };
  },
  computed: {
    dataChanged() {
      return (this.memberBaseData.firstName !== this.currentMemberData.firstName)
        || (this.memberBaseData.surname !== this.currentMemberData.surname)
        || (this.memberBaseData.address !== this.currentMemberData.address)
        || (this.memberBaseData.postCode !== this.currentMemberData.postCode)
        || (this.memberBaseData.city !== this.currentMemberData.city)
        || (this.memberBaseData.phonePrivate !== this.currentMemberData.phonePrivate)
        || (this.memberBaseData.countryCode !== this.currentMemberData.countryCode)
        || (this.memberBaseData.addressAddition !== this.currentMemberData.addressAddition);
    },
    allDataValid() {
      return this.allTrue(this.dataValid);
    },
    currentMemberData() {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.releaseMemberData;
      }
      return this.memberData;
    },
    message() {
      if (this.showSuccess) {
        return this.$t('profile.save.success');
      } else if (this.showFileSizeError) {
        return this.$t('profile.pictureSizeExceeded');
      }
      return this.$t('profile.save.failure');
    },
    toastClass() {
      if (this.showSuccess) {
        return "bg-success";
      } else if (this.showFailure || this.showFileSizeError) {
        return "bg-danger"
      }
      return "";
    },
    ...mapState(useMemberStore, {memberData: "getMemberData", isEmulatingMember: "isEmulatingMember", releaseMemberData: "getReleaseMemberData", getCountries: "getCountries"}),
    ...mapState(useLoginStore, [ "isLoggedIn", "isUser" ])
  },
  watch: {
    isEmulatingMember: {
      handler() {
        this.memberBaseData = {
          firstName: this.currentMemberData.firstName,
          surname: this.currentMemberData.surname,
          address: this.currentMemberData.address,
          addressAddition: this.currentMemberData.addressAddition,
          postCode: this.currentMemberData.postCode,
          city: this.currentMemberData.city,
          countryCode: this.currentMemberData.countryCode,
          phonePrivate: this.currentMemberData.phonePrivate,
          birthday: this.currentMemberData.birthday,
        }
      },
      immediate: true,
      deep: true,
    },
    '$i18n.locale': {
      async handler() {
        await this.loadCountries(this.$i18n.locale);
        this.countries = this.getCountries;
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    analyzeBaseData() {
      this.checkProperties(this.memberBaseData);
      this.isSubmitted = false;
    },
    validatePhoneNumber(str) {
      const phoneRegex = /^((\+|00)[1-9]{1,3})?(\s)?[0-9-().\s]{10,}$/gi;
      return phoneRegex.test(str);
    },
    submitBaseData() {
      if (this.allDataValid && this.dataChanged) {
        this.isSubmitted = true;
        this.saveMemberData().then(async response => {
          if (response && response.status === 200) {
            this.showSuccess = true;
            await this.reloadMemberData(false, true);
          } else {
            this.showFailure = true;
          }
          const type = this.showSuccess ? 'bg-success' : 'bg-danger';
          const message = this.showSuccess ? this.$t('profile.save.success') : this.$t('profile.save.failure');
          this.$router.push({
            name: 'profile',
            params: {showToast: true, type: type, message: message},
          });
        }).catch(err => {
          this.showFailure = true;
          console.log(err);
        }).finally(() => {
          this.isSubmitted = false;
          window.scrollTo(0, 0);

        });
      }
    },
    async saveMemberData() {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.saveReleaseMemberBaseData({releaseMemberId: this.releaseMemberData.memberId, baseData: this.memberBaseData}).then(response => {
          return response;
        });
      }
      return this.saveMemberBaseData(this.memberBaseData).then(response => {
        return response;
      });
    },
    async reloadMemberData(useCache, overwrite) {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.loadReleaseMemberData({useCache: useCache, releaseMemberId: this.releaseMemberData.memberId, overwrite: overwrite});
      } else {
        return this.loadMemberData(useCache, overwrite);
      }
    },
    isEmpty(str) {
      return !str?.length;
    },
    checkProperties(obj) {
      for (const key in obj) {
        this.dataValid[key] = !this.isEmpty(obj[key]);
        if (key === 'phonePrivate') {
          this.dataValid[key] = (this.validatePhoneNumber(obj[key]) && obj[key] !== null && obj[key].length > 0) || !this.phoneRequired;
        }
        if (key === 'address' || key === 'mailBox') {
          this.dataValid[key] = !this.isEmpty(obj['address']) || !this.isEmpty(obj['mailBox'])
        }
      }
    },
    allTrue(obj) {
      for (const o in obj) {
        if (!obj[o]) return false;
      }
      return true;
    },
    ...mapActions(
      useMemberStore, [ "saveMemberBaseData", "saveReleaseMemberBaseData", "loadMemberData", "loadReleaseMemberData", "loadCountries" ]
    ),
  },
  async beforeMount() {
    await this.loadCountries(this.$i18n.locale);
    this.countries = this.getCountries;
  },
  mounted() {
    this.analyzeBaseData();
    this.phoneRequired = (this.currentMemberData.phonePrivate !== undefined && this.currentMemberData.phonePrivate !== null && this.currentMemberData.phonePrivate.length > 0);

    const toastMessage = document.getElementById('toastMessage');
    this.toast = new Toast(toastMessage);
  }
};
</script>
