<template>
  <association-layout :title="t('club.etat.title')" :is-loading="isLoading" >
    <template #content  >
      <ClubEtatTable :clubId="route.query.clubId" @start-loading="isLoading = true" @stop-loading="isLoading = false"/>
      <router-link :to="{name: ASSOC_SHOW_ETAT.name, query: {associationId: route.query.associationId}}" class="btn btn-outline-secondary width-auto mt-1">
        <i class="bi bi-arrow-left me-2"></i><span class="p-button-label">{{ $t('back') }}</span>
      </router-link>
    </template>
  </association-layout>
</template>

<script setup>
import ClubEtatTable from "@/components/club/ClubEtatTable.vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {ref} from "vue";
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import {ASSOC_SHOW_ETAT} from "@/router/urls_association";


const {t} = useI18n()
const route = useRoute();
const isLoading = ref(true);


</script>
<style scoped>
</style>


