<template>
  <club-layout :title="clubData.status === 200 ? $t('club.honors.title') + ' ' + clubData.data.Name : $t('club.notFound.title')" :is-loading="false">
    <template #content v-if="clubData.status === 200">
      <div class="row">
        <div class="col">
          <h5 class="mb-3">{{ $t('club.honors.title') }}</h5>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <DataTable :value="honors"
                     scrollable scroll-height="60vh"
                     :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                     data-key="Code"
                     v-model:filters="filters" filter-display="row"
                     ref="dt" :export-filename="$t('club.honors.title').replaceAll(' ', '_')" csv-separator=";"
                     :paginator="honors.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                     row-hover class="border-bottom">

            <template #empty>
              <div class="alert alert-info">{{ $t('club.honors.list.notFound') }}</div>
            </template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column>
              <template #body="{data}">
                <div class="btn-group btn-group-sm">
                  <Button :title="$t('edit', {object: $t('club.honors.description')})" :aria-label="$t('edit', {object: $t('club.honors.description')})" unstyled @click="showEditHonorModal(data)" class="btn btn-outline-secondary"><i class="bi bi-pencil"></i></Button>
                  <Button :title="$t('delete')" :aria-label="$t('delete')" unstyled class="btn btn-outline-primary" @click="confirmDelete($event, data)">
                    <i class="bi bi-trash"></i>
                  </Button>
                </div>
              </template>
            </Column>

            <Column v-for="col in columns" :key="col.field" :field="col.field"
                    :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                    :header="col.header"
                    :sortable="true" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    :exportable="col.exportable"
                    :hidden="!col.visible">
              <template #body="{data}">
                {{ formatEntry(col.type, getDescendantProp(data, col.field)) }}
              </template>
              <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
              </template>
            </Column>

          </DataTable>
        </div>
      </div>

      <div class="row w-100 flex-center gap-2 pb-3 mb-3 mt-3">
        <Button :title="$t('club.honors.add')" :label="$t('club.honors.add')" :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm me-1" unstyled class="btn btn-outline-secondary width-auto" icon="bi bi-plus-circle me-1" @click="showEditHonorModal(selectedHonor)"/>
        <Button :title="$t('club.honors.member.add')" :label="$t('club.honors.member.add')" :loading="isModalLoadingMemberHonors" loading-icon="spinner-border spinner-border-sm me-1" unstyled class="btn btn-outline-secondary width-auto" icon="bi bi-plus-circle me-1" @click="showMemberHonorModal"/>
      </div>

      <MemberHonorsTable :club-id="clubData.data.No"/>
    </template>
  </club-layout>

  <Dialog v-model:visible="visibleHonorModal" modal block-scroll :header="selectedHonor.Code?.length ? $t('club.honors.edit') : $t('club.honors.add')" :style="{width: '30rem'}" @hide="resetHonorData">
    <div class="form-row mb-3" v-focustrap>
      <label for="honorName" class="form-label">{{ $t('club.honors.description') }}</label>
      <InputText id="honorName" autofocus v-model="selectedHonor.Description" :placeholder="$t('club.honors.description')" @update:model-value="analyzeHonorData" @keyup.enter="submitHonorData" class="w-100"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="isModalHonorDescriptionEdited && !dataValid.Description">{{ $t('club.honors.validation.descriptionEmptyOrExceeded') }}</PrimeMessage>
    </div>
    <div class="d-flex justify-content-between">
      <Button :title="$t('save')" :label="$t('save')"
              unstyled class="btn btn-outline-primary"
              :disabled="!allTrue(dataValid)"
              @click="submitHonorData"
              @keyup.enter="submitHonorData"/>
      <div class="d-flex justify-content-end">
        <Button :title="$t('delete')" :label="$t('delete')" unstyled class="btn btn-outline-primary me-2" @click="confirmDelete($event, selectedHonor)" v-if="selectedHonor.Code"/>
        <Button :title="$t('cancel')" :label="$t('cancel')" unstyled class="btn btn-outline-secondary" @click="visibleHonorModal = false"/>
      </div>
    </div>
  </Dialog>

  <Dialog v-model:visible="visibleMemberHonorModal" modal block-scroll :header="$t('club.honors.member.add')" :style="{width: '30rem'}" @hide="resetMemberHonorData">
    <div class="form-row mb-3" v-focustrap>
      <label for="memberId" class="form-label">{{ $t('institutes.functions.selectMember') }}</label>
      <Select id="memberId" v-model="selectedMemberHonor.No"
              :options="clubMemberList" option-value="memberId" :option-label="getMemberName"
              autofocus checkmark filter
              :placeholder="$t('institutes.functions.selectMember')"
              class="w-100"
              @update:modelValue="analyzeMemberHonorData"/>
    </div>
    <div class="form-row mb-3">
      <label for="honorCode" class="form-label">{{ $t('club.honors.description') }}</label>
      <Select id="honorCode" v-model="selectedMemberHonor.Honor"
              :options="honors" option-value="Code" option-label="Description"
              checkmark
              filter
              :placeholder="$t('club.honors.description')"
              class="w-100"
              @update:modelValue="analyzeMemberHonorData"/>
    </div>
    <div class="form-row mb-3">
      <label for="honorDate" class="form-label">{{ $t('date') }}</label>
      <DatePicker id="honorDate" v-model="selectedMemberHonor.Date" :model-value="formatDate(selectedMemberHonor.Date)" showIcon class="w-100" @update:modelValue="analyzeMemberHonorData"/>
    </div>
    <div class="form-row mb-3">
      <label for="honorComment" class="form-label">{{ $t('comment') }}</label>
      <Textarea id="honorComment" v-model="selectedMemberHonor.Comment" auto-resize rows="3" class="w-100" @update:modelValue="analyzeMemberHonorData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-1" v-show="!memberHonorValid.Comment">{{ $t('club.honors.validation.commentMaxLength') }}</PrimeMessage>
    </div>
    <div class="d-flex justify-content-between">
      <Button :title="$t('save')" :label="$t('save')"
              unstyled class="btn btn-outline-primary"
              :disabled="!memberHonorValid.Comment || !memberHonorValid.No || !memberHonorValid.Honor"
              @click="submitMemberHonorData"/>
      <Button :title="$t('cancel')" :label="$t('cancel')" unstyled class="btn btn-outline-secondary" @click="visibleMemberHonorModal=false"/>
    </div>
  </Dialog>

  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import ClubLayout from "@/components/club/ClubLayout.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import PrimeMessage from "primevue/message";
import Select from "primevue/select";
import DatePicker from "primevue/datepicker";
import Textarea from "primevue/textarea";

import {useClubStore} from "@/store/modules/club/club";
import {computed, ref} from "vue";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import {useI18n} from "vue-i18n";
import ConfirmDialog from "primevue/confirmdialog";
import {useConfirm} from "primevue/useconfirm";
import {FilterMatchMode} from "@primevue/core/api";
import moment from "moment";
import {useHonorsStore} from "@/store/modules/honors/honors";
import MemberHonorsTable from "@/components/honors/MemberHonorsTable.vue";

const clubStore = useClubStore();
const honorsStore = useHonorsStore();
const messageStore = useMessagesStore();
const confirm = useConfirm();

const showSuccess = ref(false);
const showFailure = ref(false);
const isModalLoading = ref(false);
const isModalLoadingMemberHonors = ref(false);
const isModalHonorDescriptionEdited = ref(false);
const visibleHonorModal = ref(false);
const {t} = useI18n();

const clubData = computed(() => {
  return clubStore.getClubData;
});

const honors = computed(() => {
  return honorsStore.getHonors;
});

const dt = ref();
const sortColumn = ref('Code');
const onSort = (event) => {
  sortColumn.value = event.sortField;
};
const columns = [
  {field: 'Code', header: t('club.honors.id'), filterable: true, exportable: true, visible: true, type: 'String'},
  {field: 'Description', header: t('club.honors.description'), filterable: true, exportable: true, visible: true, type: 'String'},
];
const filters = ref({
  Code: {value: null, matchMode: FilterMatchMode.CONTAINS},
  Description: {value: null, matchMode: FilterMatchMode.CONTAINS},
})

const getDescendantProp = (obj, key) => {
  return key.split('.').reduce((a, b) => (a && a[b] !== undefined ? a[b] : null), obj);
};

const formatEntry = (type, value) => {
  if (type === 'Date') {
    return formatDate(value);
  } else {
    return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

const selectedHonor = ref({
  Code: '',
  Club_No: clubData.value.data.No,
  Description: '',
});

const dataValid = ref({
  Description: false,
});

const showEditHonorModal = (honorData) => {
  isModalLoading.value = true;
  selectedHonor.value = JSON.parse(JSON.stringify(honorData));
  analyzeHonorDataMemberModal();
  visibleHonorModal.value = true;
  isModalLoading.value = false;
}

const resetHonorData = () => {
  selectedHonor.value = {
    Code: '',
    Club_No: clubData.value.data.No,
    Description: '',
  };
  isModalHonorDescriptionEdited.value=false;
};

const analyzeHonorDataMemberModal = () => {
  dataValid.value.Description = isNotEmptyWithinMaxLength(selectedHonor.value.Description, 30);
};

const analyzeHonorData = () => {
  isModalHonorDescriptionEdited.value = true;
  dataValid.value.Description = isNotEmptyWithinMaxLength(selectedHonor.value.Description, 30);
};

const submitHonorData = () => {
  if (allTrue(dataValid.value)) {
    saveHonorData().then(async response => {
      if (response?.status >= 200 && response?.status < 300) {
        showSuccess.value = true;
        messageStore.addInfo(new Message('info', true, false, t('save', {object: t('club.honors.description')}), '', t('club.honors.save.success'), false, 'COMPONENT'));
        await reloadHonors(clubData.value.data.No);
        await honorsStore.loadMemberHonorsByClub(clubData.value.data.No);
        visibleHonorModal.value = false;
      } else {
        showFailure.value = true;
        let combinedMessage = '';
        if (response.data) {
          combinedMessage = response.data;
        } else if (response.response.data.errors) {
          response.response.data.errors.forEach(e => {
            combinedMessage += (t(e) + '\r\n');
          });
        }
        if (!combinedMessage) {
          combinedMessage = 'errorMessages.generic_error';
        }
        messageStore.addError(new Message('error', true, false, t('save', {object: t('club.honors.description')}), '', t(combinedMessage), false, 'COMPONENT'));
      }
    }).catch(error => {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('save', {object: t('club.honors.description')}), '', error, false, 'COMPONENT'));
    });
  }
};

const saveHonorData = async () => {
  return honorsStore.saveOrUpdateClubHonor(selectedHonor.value).then(r => {
    return r;
  });
}

const confirmDelete = (event, honors) => {
  confirm.require({
    target: event.currentTarget,
    header: t('club.honors.delete.title'),
    message: t('club.honors.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary'
    },
    accept: () => {
      deleteClubHonor(honors.Club_No, honors.Code);
    },
  });
};

const deleteClubHonor = async (clubId, honorId) => {
  honorsStore.deleteClubHonors(clubId, honorId).then(r => {
    if (r.status >= 200 && r.status < 300) {
      showSuccess.value = true;
      messageStore.addInfo(new Message('info', true, false, t('club.honors.delete.title'), '', t('club.honors.delete.success'), false, 'COMPONENT'));
      visibleHonorModal.value = false;
    } else {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('club.honors.delete.title'), '', t(r.data), false, 'COMPONENT'));
    }
  }).catch(e => {
    showFailure.value = true;
    messageStore.addError(new Message('error', true, false, t('club.honors.delete.title'), '', e, false, 'COMPONENT'));
  }).finally(async () => {
    await reloadHonors(clubData.value.data.No);
  });
};

const reloadHonors = async (clubNo) => {
  await honorsStore.loadClubHonors(clubNo);
};

const visibleMemberHonorModal = ref(false);
const clubMemberList = computed(() => {
  return clubStore.getClubMembers;
});
const selectedMemberHonor = ref({
  No: '',
  Section: clubData.value.data.No,
  Honor: '',
  Date: '',
  Comment: '',
});
const memberHonorValid = ref({
  No: false,
  Section: false,
  Honor: false,
  Date: false,
  Comment: false,
});

const resetMemberHonorData = () => {
  selectedMemberHonor.value = {
    No: '',
    Section: clubData.value.data.No,
    Honor: '',
    Date: '',
    Comment: '',
  };
};

const submitMemberHonorData = () => {
  if (allTrue(memberHonorValid.value)) {
    saveMemberHonor().then(async response => {
      if (response?.status >= 200 && response?.status < 300) {
        showSuccess.value = true;
        messageStore.addInfo(new Message('info', true, false, t('club.honors.member.add'), '', t('club.honors.save.success'), false, 'COMPONENT'));
        visibleMemberHonorModal.value = false;
        await honorsStore.loadMemberHonorsByClub(clubData.value.data.No);
      } else {
        showFailure.value = true;
        let combinedMessage = '';
        if (response.data) {
          combinedMessage = response.data;
        } else if (response.response.data.errors) {
          response.response.data.errors.forEach(e => {
            combinedMessage += (t(e) + '\r\n');
          });
        }
        messageStore.addError(new Message('error', true, false, t('club.honors.member.add'), '', t(combinedMessage), false, 'COMPONENT'));
      }
    }).catch(error => {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('club.honors.member.add'), '', error, false, 'COMPONENT'));
    });
  }
};

const saveMemberHonor = async () => {
  return honorsStore.saveOrUpdateMemberHonor(selectedMemberHonor.value).then(response => {
    return response;
  });
};

const getMemberName = (member) => {
  return member.firstName + ' ' + member.surname;
};

const showMemberHonorModal = () => {
  isModalLoadingMemberHonors.value = true;
  analyzeMemberHonorData();
  loadClubMembersAndShowModal();
};

const analyzeMemberHonorData = () => {
  checkProperties(selectedMemberHonor);
};

const loadClubMembersAndShowModal = async () => {
  clubStore.loadClubMembers(clubData.value.data.No, false).then(() => {
    isModalLoadingMemberHonors.value = false;
    visibleMemberHonorModal.value = true;
  });
};

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'No') {
      memberHonorValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20);
    } else if (key === 'Section') {
      memberHonorValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 20);
    } else if (key === 'Honor') {
      memberHonorValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], 10);
    } else if (key === 'Date') {
      const value = obj.value[key];
      memberHonorValid.value[key] =
        (typeof value === 'string' && isEmpty(value)) ||
        (value instanceof Date && dateValid(value)) ||
        (!value);
    } else if (key === 'Comment') {
      memberHonorValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], 250);
    }
  }
};

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return value && (value !== '' && value?.length <= maxLength);
};

const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return value !== undefined && value !== null && (value.trim() === '' || value.trim().length <= maxLength);
};
// eslint-disable-next-line
const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value.trim() !== '';
}

const isEmpty = (value) => {
  return !value || value.trim() === '';
};

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true
};

const dateValid = (value) => {
  const dateValue = moment(String(value));
  return dateValue.isValid() && dateValue.isAfter('1900-01-01');
};
</script>

