<template>
<div>
  <div class="row">
    <div class="col">
      <h5 class="mb-3">{{ $t('club.functionsAndActivities.activity.title') }}</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <DataTable :value="clubActivities"
                 scrollable scroll-height="60vh"
                 data-key="code"
                 :loading="isLoading"
                 row-hover
                 class="border-bottom">

        <template #empty>
          <div class="alert alert-info">{{ $t('club.functionsAndActivities.activity.notFound') }}</div>
        </template>

        <Column>
          <template #body="{data}" >
            <div class="btn-group btn-group-sm pe-2">
              <Button :title="$t('club.functionsAndActivities.activity.edit.title')" :aria-label="$t('club.functionsAndActivities.activity.edit.title')"
                      unstyled class="btn btn-outline-secondary" @click="editClubActivity(data)" data-bs-toggle="modal" data-bs-target="#edit-club-activity-modal" >
                <i class="bi bi-pencil"></i>
              </Button>
              <Button :title="$t('club.functionsAndActivities.activity.delete.title')" :aria-label="$t('club.functionsAndActivities.activity.delete.title')"
                      unstyled class="btn btn-outline-primary" @click="confirmDelete($event, data.code)" >
                <i class="bi bi-trash"></i>
              </Button>
            </div>
          </template>
        </Column>

        <Column field="description" :header="$t('club.functionsAndActivities.activity.description')">
          <template #body="{data}">
            {{ data.description }}
          </template>
        </Column>

        <Column field="points" :header="$t('club.functionsAndActivities.activity.pointsEachYear')">
          <template #body="{data}">
            {{ data.pointsEachYear }}
          </template>
        </Column>
      </DataTable>
      <div class="row w-100 flex-center gap-2 mb-3 mt-3">
      <Button :title="$t('club.functionsAndActivities.activity.titleNew')"
              :label="$t('club.functionsAndActivities.activity.titleNew')"
              unstyled class="btn btn-outline-secondary width-auto" icon="bi bi-plus-circle me-2"
              data-bs-toggle="modal" data-bs-target="#edit-club-activity-modal"
              @click="addClubActivity()"/>
      </div>

    </div>
  </div>

  <ClubActivityEditModal id="edit-club-activity-modal" :activity="activityToEdit" :create-new="createNewModeForModal"
                         @club-activities-changed="reloadClubActivities()"></ClubActivityEditModal>
</div>
</template>
<script setup>

import {onBeforeMount, ref, watch} from "vue";
import ApiClient from "@/api/ApiClient";
import Button from "primevue/button";
import {useI18n} from "vue-i18n";
import {useConfirm} from "primevue/useconfirm";
import ClubActivityEditModal from "@/components/activity/ClubActivityEditModal.vue";
import Message from "@/helpers/message";
import {useMessagesStore} from "@/store/modules/messages/messages";

const {t} = useI18n();
const confirm = useConfirm();
const messagesStore = useMessagesStore();


const props = defineProps({
  clubId: String}
);

const isLoading = ref(false);
const clubActivities = ref([]);

const confirmDelete = (event, clubActivityData) => {
  confirm.require({
    target: event.currentTarget,
    header: t('club.functionsAndActivities.delete.title'),
    message: t('club.functionsAndActivities.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      deleteClubActivity(clubActivityData);
    },
  });
};

const activityToEdit = ref(null);
const createNewModeForModal = ref(false);

async function addClubActivity() {
  activityToEdit.value = {
    description: "",
    pointsEachYear: null,
    clubNo: props.clubId,
    code: null,
    odataEtag: null};
  createNewModeForModal.value = true;
}

async function editClubActivity(clubActivity) {
  activityToEdit.value = clubActivity;
  createNewModeForModal.value = false;

}

async function deleteClubActivity(clubActivityId) {
  const request = "/api/activity/club?clubId=" + props.clubId + "&clubActivityId=" + clubActivityId;
  const response = await ApiClient.deleteRequest(request);

  if (response && response.status === 204) {
    messagesStore.addInfo(new Message('info', true, false,
      t('club.functionsAndActivities.activity.successDeleteClubActivityHeader'), "", t('club.functionsAndActivities.activity.successDeleteClubActivity')))
    await loadClubActivities(props.clubId)
  } else if (response && response.data) {
    messagesStore.addError(new Message('error', true, false, t('club.functionsAndActivities.activity.errorDeleteClubActivityHeader'), "", response.data));
  } else {
    messagesStore.addError(new Message('error', true, false, t('club.functionsAndActivities.activity.errorDeleteClubActivityHeader'), "", t('errorMessages.generic_error')));
  }
}

async function reloadClubActivities() {
  await loadClubActivities(props.clubId);
}

async function loadClubActivities(clubId) {
  const request = "/api/activity/club?clubId=" + clubId;

  const response = await ApiClient.getRequest(request);

  if(response && response.status === 200) {
    clubActivities.value = response.data;
    isLoading.value = false;
  }
}

watch(props, () => {
  loadClubActivities(props.clubId);
})

onBeforeMount(() => {
  isLoading.value = true;
  loadClubActivities(props.clubId);
})
</script>
