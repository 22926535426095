<template>
  <profile-layout :title="currentMemberData.firstName + ' ' + currentMemberData.surname + ' / ' + $t('profile.showProfile')" :is-loading="false">
    <template #content>
      <div class="row border-bottom pb-4 mb-4" v-if="!isEmulatingMember">
        <div class="col d-flex align-items-center gap-4">
          <UserAvatar avatar-class="profile img-fluid" v-if="profileImage">
            <button type="button" class="img-profile-overlay" data-bs-toggle="modal" data-bs-target="#imageCropperModal">
              <span>
                <span class="display-3"><i class="bi bi-camera"></i></span><br>
                {{ $t('profile.changePicture') }}
              </span>
            </button>
          </UserAvatar>
          <h2>
            {{ currentMemberData.firstName }} {{ currentMemberData.surname }}
            <button type="button"
                    class="btn btn-outline-secondary d-block mt-3"
                    data-bs-toggle="modal" data-bs-target="#imageCropperModal">
              {{ profileImage ? $t('profile.changePicture') : $t('profile.uploadNewPicture') }}
            </button>
          </h2>
        </div>
      </div>
      <div class="row border-bottom pb-4 mb-4" v-if="practisedSports && practisedSports.length > 0">
        <div class="col">
          <h5 class="mb-3">{{ $t('sport.title', practisedSports.length) }}</h5>
          <div class="container-fluid row overflow-auto p-0 m-0">
            <div class="col d-flex gap-3 flex-nowrap align-items-stretch p-0 mb-3">
              <card-container card-class="text-center sports-card" v-for="sport in practisedSports" :key="sport">
                <template #content>
                  <svg width="60" height="60">
                    <use :xlink:href="require('@/assets/img/sport-icons.svg') + '#' + sport"/>
                  </svg>
                  <small>{{ $t('sport.' + sport) }}</small>
                </template>
              </card-container>
            </div>
          </div>
          <router-link :to="$router.resolve({name: 'change-sports'})" class="btn btn-outline-secondary d-inline-block mt-3">
            {{ $t('breadcrumb.changeSports') }}
          </router-link>

        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5 class="mb-3">{{ $t('profile.contact') }}</h5>
          <p class="mb-2"><strong>{{ $t('profile.address') }}</strong></p>
          <p class="text-muted"><em>{{ $t('profile.addressInfo') }}</em></p>
          <p>
            {{ currentMemberData.address }}<br>
            {{ currentMemberData.addressAddition }}<br v-if="currentMemberData.addressAddition">
            {{ currentMemberData.postCode }} {{ currentMemberData.city }}<br v-if="currentMemberData.countryCode">
            {{ country }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-2"><strong>{{ $t('profile.phone.title') }}</strong></p>
          <p class="text-muted"><em>{{ $t('profile.phone.info') }}</em></p>
          <p v-if="currentMemberData.phonePrivate">{{ currentMemberData.phonePrivate }}</p>
          <p class="text-danger" v-else>{{ $t('profile.phone.noPhoneNumber') }}</p>
        </div>
      </div>
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <router-link :to="$router.resolve({name: 'change-address'})" class="btn btn-outline-secondary">
            {{ $t('breadcrumb.changeProfile') }}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5>{{ $t('profile.email.title') }}</h5>
          <p class="text-muted"><em>{{ $t('profile.emailInfo') }}</em></p>
          <p>{{ currentMemberData.emailPrivate }}</p>
        </div>
      </div>
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <router-link to="change-email" class="btn btn-outline-secondary">
            {{ $t('profile.email.changeEmail') }}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5>{{ $t('profile.birthday.title') }}</h5>
          <p class="text-muted"><em>{{ $t('profile.birthday.info') }}</em></p>
          <p>{{ formatDate(currentMemberData.birthday, 'DD.MM.YYYY') }}</p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
          <router-link to="change-birthday" class="btn btn-outline-secondary">
            {{ $t('profile.birthday.changeBirthday') }}
          </router-link>
        </div>
      </div>

      <div id="toastWrapper" class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;"></div>
    </template>
  </profile-layout>
  <ProfileImageModal :member-data="memberData" @toast-show="showToastMessage"/>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {Toast} from "bootstrap";

import ProfileLayout from "@/components/profile/ProfileLayout";
import UserAvatar from "@/components/personal-data/UserAvatar";
import CardContainer from "@/components/ui/CardContainer.vue";
import ProfileImageModal from "@/components/ui/ProfileImageModal.vue";

import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useProfileStore} from "@/store/modules/profile/profile.js";
import toast from "bootstrap/js/src/toast";


export default {
  data() {
    return {
      showToast: false,
      successMessage: '',
    };
  },
  components: {
    CardContainer,
    ProfileLayout,
    UserAvatar,
    ProfileImageModal,
  },
  computed: {
    toast() {
      return toast
    },
    currentMemberData() {
      if (this.isEmulatingMember) {
        this.initCountry(this.releaseMemberData.countryCode)
        return this.releaseMemberData;
      }
      this.initCountry(this.memberData.countryCode)
      return this.memberData;
    },
    practisedSports() {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.releaseMemberSports;
      }
      return this.sportsData;
    },
    country() {
      let country = this.getCountry
      if (country !== null || this.$i18n.locale === undefined) {
        return this.$i18n.locale === 'fr' ? country.nameFr : country.nameDe
      }
      return ''
    },
    ...mapState(useLoginStore, [ "isLoggedIn", "isUser" ]),
    ...mapState(useMemberStore, {memberData: "getMemberData", isEmulatingMember: "isEmulatingMember", releaseMemberData: "getReleaseMemberData", sportsData: "getSportsData", releaseMemberSportsData: "getReleaseMemberSportsData", getCountry: "getCountry"}),
    ...mapState(useProfileStore, {profileImage: "getProfileImage"}),

  },
  methods: {
    createToastMessage(type, message) {
      const toastWrapper = document.getElementById('toastWrapper');
      const toastMessage = document.createElement('div');
      toastMessage.setAttribute('id', 'toastMessage');
      toastMessage.setAttribute('role', 'alert');
      toastMessage.setAttribute('aria-live', 'assertive');
      toastMessage.setAttribute('aria-atomic', 'true');
      toastMessage.classList.add('toast', 'align-items-center', 'text-white', type);
      toastMessage.innerHTML = `<div class="d-flex"><div class="toast-body">${message}</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>`;
      toastWrapper.appendChild(toastMessage);
    },
    showToastMessage(showSuccess, message) {
      const type = showSuccess ? 'bg-success' : 'bg-danger';
      this.createToastMessage(type, message);
      const toastMessage = document.querySelector('#toastMessage');
      const toast = new Toast(toastMessage);
      toast.show();
    },
    initCountry(country) {
      if (country) {
        this.loadCountry(country)
      }
    },
    ...mapActions(
      useMemberStore, [ "loadCountry" ]
    ),
  },
  beforeMount() {
    this.showToast = (this.$route.params.showToast);
    this.successMessage = this.$route.params.message;
  },
  mounted() {
    if (this.showToast) {
      this.createToastMessage(this.$route.params.type, this.$route.params.message);
      const toastMessage = document.getElementById('toastMessage');
      const toast = new Toast(toastMessage);
      toast.show();
    }
  }
}
</script>

