<template>
  <club-layout :title="$t('club.sponsor.profile', {stvNr: memberBaseData.memberId, name: memberBaseData.name, name2: memberBaseData.name2})" :is-loading="isSubmitted">
    <template #content>
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <div class="row mb-3">
            <div class="col-12">
              <h5 class="mb-3">{{ $t('club.member.titles.profile') }}</h5>
              <p class="mb-2"><strong>{{ $t('club.member.stvNumber') }}</strong></p>
              <p v-if="memberBaseData.memberId !== 'new'">
                {{ memberBaseData.memberId }}<br>
              </p>
              <p v-else>
                {{ $t('club.member.new') }}
              </p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.name') }} *</strong></p>
              <InputText v-model="memberBaseData.name" :invalid="!dataValid.name" :aria-label="$t('club.member.name')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.name">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.name'), maxLength50]) }}</PrimeMessage>
            </div>
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.name2') }}</strong></p>
              <InputText v-model="memberBaseData.name2" :invalid="!dataValid.name2" :aria-label="$t('club.member.name2')" @update:modelValue="analyzeBaseData" class="mb-1 w-100"/>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.name2">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.name2'), maxLength50]) }}</PrimeMessage>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.language') }}</strong></p>
              <Select v-model="memberBaseData.language" :invalid="!dataValid.language" :options="languageOptions" optionLabel="name" optionValue="code" checkmark :aria-label="$t('club.member.language')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.language">{{ $t('club.member.validation.invalidSelection', [$t('club.member.language')]) }}</PrimeMessage>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <h5 class="mb-3">{{ $t('club.member.titles.address') }}</h5>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.postCode') }} *</strong></p>
              <AutoComplete v-model="memberBaseData.postCode" optionLabel="ZipCode" :suggestions="autocompleteZipPlaceSelection" @beforeinput="checkForDigits"
                            @complete="autocompletePlace" @option-select="applyPlace" inputClass="mb-1 w-100" :completeOnFocus=true :minLength=2>
                <template #option="slotProps">
                  <div class="flex items-center">
                    <div>{{ slotProps.option.ZipCode }} {{ slotProps.option.TownName }}</div>
                  </div>
                </template>
              </AutoComplete>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.postCode">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.postCode'), maxLength20]) }}</PrimeMessage>
            </div>
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.city') }} *</strong></p>
              <AutoComplete v-model="memberBaseData.city" optionLabel="ZipCode" :suggestions="autocompleteZipPlaceSelection" @complete="autocompletePlace" @option-select="applyPlace" inputClass="mb-1 w-100" :completeOnFocus=true :minLength=2>
                <template #option="slotProps">
                  <div class="flex items-center">
                    <div>{{ slotProps.option.ZipCode }} {{ slotProps.option.TownName }}</div>
                  </div>
                </template>
              </AutoComplete>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.city">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.city'), maxLength30]) }}</PrimeMessage>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.address') }} *</strong></p>
              <AutoComplete v-model="memberBaseData.address" optionLabel="StreetName" :suggestions="autocompleteSelection" @complete="autocompleteAddress" @option-select="applyAddress" inputClass="mb-1 w-100" :completeOnFocus=true :minLength=2>
                <template #option="slotProps">
                  <div class="flex items-center">
                    <div>{{ slotProps.option.StreetName }} {{ slotProps.option.HouseNo }}{{ slotProps.option.HouseNoAddition }}, {{ slotProps.option.ZipCode }} {{ slotProps.option.TownName }}</div>
                  </div>
                </template>
              </AutoComplete>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.address">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.address'), maxLength50]) }}</PrimeMessage>
            </div>
            <div class="col-6">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.addressAddition') }}</strong></p>
              <InputText v-model="memberBaseData.addressAddition" :invalid="!dataValid.addressAddition" :aria-label="$t('club.member.addressAddition')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.addressAddition">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.addressAddition'), maxLength50]) }}</PrimeMessage>
            </div>
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.addressAddition2') }}</strong></p>
              <InputText v-model="memberBaseData.addressAddition2" :invalid="!dataValid.addressAddition2" :aria-label="$t('club.member.addressAddition2')" @update:modelValue="analyzeBaseData" class="mb-1 w-100"/>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.addressAddition2">{{ $t('club.member.validation.emptyOrExceeded', [$t('club.member.addressAddition2'), maxLength50]) }}</PrimeMessage>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.county') }}</strong></p>
              <Select v-if="i18nLocale === 'fr'" v-model="memberBaseData.county" :invalid="!dataValid.county" :options="counties" optionLabel="nameFr" optionValue="code" checkmark :aria-label="$t('club.member.county')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
              <Select v-else v-model="memberBaseData.county" :invalid="!dataValid.county" :options="counties" optionLabel="nameDe" optionValue="code" checkmark :aria-label="$t('club.member.county')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" />
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.county">{{ $t('club.member.validation.invalidSelection', [$t('club.member.county')]) }}</PrimeMessage>
            </div>
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.country') }}</strong></p>
              <Select v-model="memberBaseData.countryCode" :invalid="!dataValid.countryCode" :options="countries" optionLabel="name" optionValue="code" :aria-label="$t('club.member.country')" @update:modelValue="analyzeBaseData" class="mb-1 w-100" :placeholder="$t('club.member.country')">
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex items-center">
                      <img alt="" src="@/assets/img/flag_placeholder.png" :class="`vti__flag ${slotProps.value.toLowerCase()}`" />
                      <div v-if="i18nLocale === 'fr'">{{ findCountry(slotProps.value).nameFr }}</div>
                      <div v-else>{{ findCountry(slotProps.value).nameDe }}</div>
                  </div>
                  <span v-else>
                      {{ slotProps.placeholder }}
                  </span>
              </template>
              <template #option="slotProps">
                  <div class="flex items-center" v-if="i18nLocale === 'fr'">
                      <img alt="" src="@/assets/img/flag_placeholder.png" :class="`vti__flag ${slotProps.option.code.toLowerCase()}`" />
                      <div>{{ slotProps.option.nameFr }}</div>
                  </div>
                  <div class="flex items-center" v-else>
                      <img alt="" src="@/assets/img/flag_placeholder.png" :class="`vti__flag ${slotProps.option.code.toLowerCase()}`" />
                      <div>{{ slotProps.option.nameDe }}</div>
                  </div>
              </template>
              </Select>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.countryCode">{{ $t('club.member.validation.invalidSelection', [$t('club.member.country')]) }}</PrimeMessage>
            </div>
            <div class="col-6">

            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <h5 class="mb-3">{{ $t('club.member.titles.communication') }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.phonePrivate') }}</strong></p>
              <vue-tel-input v-model="memberBaseData.phonePrivate" mode="international" :required=true @validate="validatePhonePrivate" class="mb-1"></vue-tel-input>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.phonePrivate && memberBaseData.phonePrivate">{{ $t('club.member.validation.invalidNumber', [$t('club.member.phonePrivate')]) }}</PrimeMessage>
            </div>
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.phoneBusiness') }}</strong></p>
              <vue-tel-input v-model="memberBaseData.phoneBusiness" mode="international" @validate="validatePhoneBusiness" class="mb-1"></vue-tel-input>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.phoneBusiness && memberBaseData.phoneBusiness">{{ $t('club.member.validation.invalidNumber', [$t('club.member.phoneBusiness')]) }}</PrimeMessage>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.phoneMobile') }}</strong></p>
              <vue-tel-input v-model="memberBaseData.phoneMobile" mode="international" @validate="validatePhoneMobile" class="mb-1"></vue-tel-input>
              <PrimeMessage severity="error" icon="bi bi-x-circle" v-show="!dataValid.phoneMobile && memberBaseData.phoneMobile">{{ $t('club.member.validation.invalidNumber', [$t('club.member.phoneMobile')]) }}</PrimeMessage>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.emailPrivate') }}</strong></p>
              <MailInputProfile v-model.lowercase="memberBaseData.emailPrivate" :label="$t('club.member.emailPrivate')" id="email-private" @on-change="onMailPrivateChange" :validationFailed="!dataValid.emailPrivate" :validationMessage="dataValidMessage.emailPrivate"></MailInputProfile>
            </div>
            <div class="col-6">
              <p class="mb-2"><strong>{{ $t('club.member.emailAlternative') }}</strong></p>
              <MailInputProfile v-model.lowercase="memberBaseData.emailAlternative" :label="$t('club.member.emailAlternative')" id="email-alternative" @on-change="onMailAlternativeChange" :validationFailed="!dataValid.emailAlternative" :validationMessage="dataValidMessage.emailAlternative"></MailInputProfile>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-bottom pb-4 mb-4">
        <div class="col">
          <div class="row mb-3">
            <div class="col-12">
              <p class="mb-2"><strong>{{ $t('club.member.remarks') }}</strong></p>
              <Textarea v-model="memberBaseData.mySTVComment" class="mb-1 w-100" :placeholder="$t('club.member.remarks')"></Textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="d-flex gap-2">
          <DefaultButton
            class="btn-outline-primary"
            :label="$t('club.member.save.buttonText')"
            @click="submitBaseData"
            @keyup.enter="submitBaseData"
            onkeypress="submitBaseData"
            :disabled="(!allDataValid || isSubmitted) || !dataChanged"
          />
          <DefaultButton class="btn-outline-secondary" v-if="memberBaseData.memberId !== 'new'"
                         :label="$t('section.clubMembers.deletePartner')"
                          @click="deletePartnerMembership($event)"/>
          <DefaultButton
            class="btn-outline-secondary"
            :label="$t('cancel')"
            @click="cancel"
            onkeypress="cancel"
          />
        </div>
      </div>
      <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
      <BootstrapToast />
    </template>
  </club-layout>

</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import {useMemberStore} from "@/store/modules/member/member.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import {useAutocompleteStore} from "@/store/modules/autocomplete/autocomplete.js";
import {useClubStore} from '@/store/modules/club/club.js';
import {useClubMemberStore} from '@/store/modules/clubMember/clubMember.js';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n'
import {useConfirm} from "primevue/useconfirm";

import ClubLayout from "@/components/club/ClubLayout";
import MailInputProfile from "@/components/ui/MailInputProfile.vue";
import Message from "@/helpers/message.js";
import BootstrapToast from '@/components/ui/BootstrapToast.vue';
import InputText from 'primevue/inputtext';
import PrimeMessage from 'primevue/message';
import Select from 'primevue/select';
import Textarea from 'primevue/textarea';
import AutoComplete from 'primevue/autocomplete';
import DefaultButton from "@/components/ui/DefaultButton.vue";
import ConfirmDialog from "primevue/confirmdialog";
import ApiClient from "@/api/ApiClient";

const confirm = useConfirm();
const router = useRouter();
const messageStore = useMessagesStore();
const memberStore = useMemberStore();
const clubStore = useClubStore();
const clubMemberStore = useClubMemberStore();
const autocompleteStore = useAutocompleteStore();
const minLengthYearInput = 4;
const maxLengthYearInput = 4;
const maxLengthInput = 80;
const minLength03 = 3;
const maxLength06 = 6;
const maxLength20 = 20;
const maxLength30 = 30;
const maxLength50 = 50;
const maxLength2000 = 2000;
const currentMemberData = computed(() => memberStore.getSingleMemberData);
const { t } = useI18n()
const countries = ref([]);
const counties = ref([]);

const addressRegex = /^([\D.s-]+[\s]?)([\d]+)?([\s]?[\D]+?)?$/;
const autocompleteSelection = ref([]);
const autocompleteZipPlaceSelection = ref([]);

const i18nLocale = ref(useI18n().locale.value);

const codeDe = 'DE';
const codeFr = 'FR';
const codeIt = 'IT';
const codeEn = 'EN';
const languageOptions = ref([
  { name: t('languages.de'), code: codeDe },
  { name: t('languages.fr'), code: codeFr },
  { name: t('languages.it'), code: codeIt },
  { name: t('languages.en'), code: codeEn}
]);

const showFailure = ref(false);
const showSuccess = ref(false);
const isSubmitted = ref(false);
// eslint-disable-next-line no-unused-vars
const memberBaseData = ref({
  name: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.name,
  name2: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.name2,
  salutation: !currentMemberData.value.stvMember ? 'U' : currentMemberData.value.stvMember.salutation,
  language: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.language,
  address: !currentMemberData.value.stvMember ? "" : currentMemberData.value.stvMember.address,
  postCode: !currentMemberData.value.stvMember ? "" : currentMemberData.value.stvMember.postCode,
  city: !currentMemberData.value.stvMember ? "" : currentMemberData.value.stvMember.city,
  emailPrivate: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.emailPrivate,
  emailAlternative: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.emailAlternative,
  phonePrivate: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.phonePrivate,
  phoneBusiness: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.phoneBusiness,
  phoneMobile: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.phoneMobile,
  countryCode : !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.countryCode,
  addressAddition: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.addressAddition,
  addressAddition2: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.addressAddition2,
  memberId: !currentMemberData.value.stvMember ? 'new' : currentMemberData.value.stvMember.memberId,
  dateOfDeath: !currentMemberData.value.stvMember || currentMemberData.value.stvMember.dateOfDeath === null ? null : new Date(currentMemberData.value.stvMember.dateOfDeath),
  county: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.county,
  mySTVComment: !currentMemberData.value.stvMember ? null : currentMemberData.value.stvMember.mySTVComment
})
const dataValid = ref({
  name: false,
  name2: false,
  salutation: true,
  language: false,
  address: false,
  postCode: false,
  city: false,
  emailPrivate: false,
  emailAlternative: false,
  phonePrivate: false,
  phoneBusiness: false,
  phoneMobile: false,
  countryCode : false,
  addressAddition: false,
  addressAddition2: false,
  sex: true,
  county: false
})

const dataValidMessage = ref({
  emailPrivate: null,
  emailAlternative: null,
})

const nonDigitRegex = /\D/; // Regex literal für 'non-digit character'

const checkForDigits = (event) => {
  if(event.data && nonDigitRegex.test(event.data)) {
    event.preventDefault(); //Verhindert input, wenn input nicht nur aus Ziffern besteht.
  }
}


const dataChanged = computed(() => {
  return (memberBaseData.value.memberId === "new"
    || memberBaseData.value.name !== currentMemberData.value.stvMember.name)
    || (memberBaseData.value.name2 !== currentMemberData.value.stvMember.name2)
    || (memberBaseData.value.language !== currentMemberData.value.stvMember.language)
    || (memberBaseData.value.address !== currentMemberData.value.stvMember.address)
    || (memberBaseData.value.postCode !== currentMemberData.value.stvMember.postCode)
    || (memberBaseData.value.city !== currentMemberData.value.stvMember.city)
    || (memberBaseData.value.emailPrivate !== currentMemberData.value.stvMember.emailPrivate)
    || (memberBaseData.value.emailAlternative !== currentMemberData.value.stvMember.emailAlternative)
    || (memberBaseData.value.phonePrivate !== currentMemberData.value.stvMember.phonePrivate)
    || (memberBaseData.value.phoneBusiness !== currentMemberData.value.stvMember.phoneBusiness)
    || (memberBaseData.value.phoneMobile !== currentMemberData.value.stvMember.phoneMobile)
    || (memberBaseData.value.countryCode !== currentMemberData.value.stvMember.countryCode)
    || (memberBaseData.value.addressAddition !== currentMemberData.value.stvMember.addressAddition)
    || (memberBaseData.value.addressAddition2 !== currentMemberData.value.stvMember.addressAddition2)
    || (memberBaseData.value.memberId !== currentMemberData.value.stvMember.memberId)
    || (memberBaseData.value.county !== currentMemberData.value.stvMember.county)
    || (memberBaseData.value.mySTVComment !== currentMemberData.value.stvMember.mySTVComment)
})

const allDataValid = computed(() => {
  return allTrue(dataValid.value);
})

const analyzeBaseData = () =>  {
  checkProperties(memberBaseData);
  isSubmitted.value = false;
}

const submitBaseData = () => {
  if (allDataValid.value && dataChanged) {
    isSubmitted.value = true;
    resetValidationMessages();
    saveSponsorData().then(async response => {
      if (response && response.status === 200) {
        showSuccess.value = true;
      } else {
        showFailure.value = true;
      }

      if(showSuccess.value) {
        messageStore.addInfo(new Message('info', true, false, t('club.sponsor.profile', {stvNr: memberBaseData.value.memberId === 'new' ? t('club.sponsor.new'): memberBaseData.value.memberId,
          name: memberBaseData.value.name, name2: memberBaseData.value.name2}), '', t('club.sponsor.save.success'), true, 'COMPONENT'));
        isSubmitted.value = false;
        router.push({
          name: 'club-sponsors-partners',
          query: {clubId: clubStore.getCurrentClubNo}
        });
      } else {
        if(response.data.property) {
          dataValid.value[response.data.property] = false;
          dataValidMessage.value[response.data.property] = response.data.message;
        } else {
          messageStore.addError(new Message('error', true, false, t('club.sponsor.profile', {stvNr: memberBaseData.value.memberId, firstName: memberBaseData.value.firstName, surname: memberBaseData.value.surname}), '', t(''+response.data.message+''), true, 'COMPONENT'));
        }
        messageStore.addError(new Message('error', true, false, t('club.sponsor.profile', {stvNr: memberBaseData.value.memberId, name: memberBaseData.value.name, name2: memberBaseData.value.name2}), '', t('club.sponsor.save.failure'), true, 'COMPONENT'));
        isSubmitted.value = false;
      }

    }).catch(err => {
      showFailure.value = true;
      console.log(err);
    }).finally(() => {
      isSubmitted.value = false;
      window.scrollTo(0, 0);
    });
  }
}

const saveSponsorData = async () => {
  const payload = {
    releaseMemberId: memberBaseData.value.memberId,
    baseData : memberBaseData,
    clubId: clubStore.getCurrentClubNo
  };

  return clubMemberStore.saveClubSponsor(payload).then(response => {
    return response;
  });
}

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'Year_Established') {
      dataValid.value[key] = isEmptyOrWithinMinAndMaxLength(obj.value[key], minLengthYearInput, maxLengthYearInput);
    } else if(key === 'city') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], maxLength30);
    } else if(key === 'name' || key === 'address') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], maxLength50);
    } else if(key === 'swissAthleticsNr') {
      dataValid.value[key] = isEmptyOrWithinMinAndMaxLength(obj.value[key], minLength03, maxLength06);
    } else if(key === 'language') {
      dataValid.value[key] = isEmpty(obj.value[key]) || obj.value[key] === codeDe || obj.value[key] === codeFr || obj.value[key] === codeIt || obj.value[key] === codeEn;
    } else if(key === 'countryCode') {
      dataValid.value[key] = isEmpty(obj.value[key]) || countries.value.find(c => c.code === obj.value[key]);
    } else if(key === 'county') {
      dataValid.value[key] = isEmpty(obj.value[key]) || counties.value.find(c => c.code === obj.value[key]);
    } else if(key === 'postCode') {
      dataValid.value[key] = isNotEmptyWithinMaxLength(obj.value[key], maxLength20);
    } else if(key === 'addressAddition' || key === 'addressAddition2' || key === 'name2') {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], maxLength50);
    } else if(key === 'mySTVComment') {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], maxLength2000);
    } else {
      dataValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], maxLengthInput);
    }
  }
}

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
}

const onMailPrivateChange = (mailValidationState) => {
  dataValid.value.emailPrivate = mailValidationState;
}

const onMailAlternativeChange = (mailValidationState) => {
  dataValid.value.emailAlternative = mailValidationState;
}

const validatePhonePrivate = (event) => {
  dataValid.value.phonePrivate = event.valid ? event.valid : false
}

const validatePhoneBusiness = (event) => {
  dataValid.value.phoneBusiness = event.valid ? event.valid : false
}

const validatePhoneMobile = (event) => {
  dataValid.value.phoneMobile = event.valid ? event.valid : false
}

const isEmpty = (value) => {
  return !value;
}

const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return !value || (value === '' || value.length <= maxLength);
}

const isEmptyOrWithinMinAndMaxLength = (value, minLength, maxLength) => {
  return !value || (value === '' || (value.length <= maxLength && value.length >= minLength));
}

const isNotEmptyWithinMaxLength = (value, maxLength) => {
  return value && value !== '' && value.length <maxLength;
}

const findCountry = (country) => {
  if(countries.value !== null && countries.value.length > 0) {
    return countries.value.find(c => c.code === country)
  }
  return {};
}

const autocompleteAddress = async () => {
  let street = "";
  let streetNumber = "";
  let streetNumberAddition= "";
  if(memberBaseData.value.address && memberBaseData.value.address.length > 0) {
    let groups = memberBaseData.value.address.match(addressRegex);
    street = groups[0];
    if(groups.length > 1) {
      street = groups[1] !== undefined ? groups[1] : "";
      streetNumber = groups[2] !== undefined ? groups[2] : "";
      streetNumberAddition = groups[3] !== undefined ? groups[3] : "";
    }
  }
  let result = await autocompleteStore.autocompleteAddress(street, streetNumber, streetNumberAddition, memberBaseData.value.postCode, memberBaseData.value.city);
  if(result.data) {
    autocompleteSelection.value = result.data;
  } else {
    autocompleteSelection.value = [];
  }
  analyzeBaseData();
}

const autocompletePlace = async () => {

  let result = await autocompleteStore.autocompleteAddress("", "", "", memberBaseData.value.postCode, memberBaseData.value.city);
  if(result.data) {
    autocompleteZipPlaceSelection.value = result.data;
  } else {
    autocompleteZipPlaceSelection.value = [];
  }
  analyzeBaseData();
}

const applyAddress = (event) => {
  let result = event.value;
  memberBaseData.value.address = result.StreetName + " " + result.HouseNo + result.HouseNoAddition;
  memberBaseData.value.postCode = result.ZipCode;
  memberBaseData.value.city = result.TownName;
  memberBaseData.value.county = result.Canton;
  memberBaseData.value.countryCode = result.CountryCode;
  analyzeBaseData();
}

const applyPlace = (event) => {
  let result = event.value;

  memberBaseData.value.postCode = result.ZipCode;
  memberBaseData.value.city = result.TownName;
  memberBaseData.value.county = result.Canton;
  memberBaseData.value.countryCode = result.CountryCode;
  analyzeBaseData();
}

const deletePartnerMembership = (event) => {
  confirm.require({
    target: event.currentTarget,
    header: t('section.clubMembers.deletePartner'),
    message: t('section.clubMembers.deletePartnerQuestion'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('section.clubMembers.deletePartner'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      let request = "/api/clubmember/remove/club-sponsor?clubNo=" + clubStore.getCurrentClubNo + "&sponsorNo=" + currentMemberData.value.memberId;
      ApiClient.deleteRequest(request).then((response) => {
        if(response.status === 204) {
          messageStore.addInfo(new Message('info', true, false, t('club.sponsor.successRemove'), '',
            t('club.sponsor.successRemoveMessage'), true, 'COMPONENT'));
          router.push({
            name: 'club-sponsors-partners',
            query: {clubId: clubStore.getCurrentClubNo}
          });
        } else if (response.data.error) {
          messageStore.addError(new Message('error', true, false,
            t('errorMessages.generic_error'), '', response.data.error.message));
        } else {
          messageStore.addError(new Message('error', true, false,
            t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
        }
      })
    },
  });
}

const cancel = () => {
  router.push({
          name: 'club-sponsors-partners',
          query: {clubId: clubStore.getCurrentClubNo}
        });
}

const resetValidationMessages = () => {
  dataValidMessage.value.emailPrivate=null;
  dataValidMessage.value.emailAlternative=null;
}

onMounted(() => {
})

;(async () => {
  await memberStore.loadCounties(i18nLocale.value);
  await memberStore.loadCountries(i18nLocale.value);
  counties.value = memberStore.getCounties;
  countries.value = memberStore.getCountries;
  analyzeBaseData();
 })()

</script>

<style scoped>

  .p-inputmask {
    width: 100%;
    margin-bottom: .25rem;
  }
</style>
