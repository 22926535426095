<template>
  <profile-layout :title="currentMemberData.firstName + ' ' + currentMemberData.surname + ' / ' + $t('profile.email.changeEmail')" :is-loading="isSubmitted">
    <template #content>
      <div class="row mb-4">
        <div class="col">
          <h5>{{ $t('profile.email.currentEmail') }}</h5>
          <p>{{ currentMemberData.email }}</p>
        </div>
      </div>

      <div class="row mb-3" v-if="!showSuccess">
        <div class="col-12 mb-3">
          <h5 class="mb-3">{{ $t('profile.email.newEmail') }}</h5>
          <div class="form-floating has-validation">
            <input type="email" id="email-new" class="form-control"
                   :class="{'is-invalid': !responseMailValid, 'is-valid': emailValid}"
                   autocomplete="off"
                   :placeholder="$t('profile.email.newEmail')"
                   v-model="newEmail"
                   @keyup="analyzeEmail"
                   @change="analyzeEmail"
                   @keyup.enter="submitEmail"
                   :disabled="isSubmitted">
            <label for="email-new" class="form-label">{{ $t('profile.email.newEmail') }}</label>
            <div class="invalid-feedback" v-if="!responseMailValid">{{ errorResponseMail }}</div>
            <div class="invalid-feedback" v-if="!emailValid">{{ $t('profile.email.wrongEmail') }}</div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="password" class="form-label">
            <i class="bi bi-exclamation-triangle me-1" style="font-size: 1.25rem;"></i>
            {{ $t('profile.email.enterPassword') }}
          </label>
          <p><em class="text-muted font-weight-normal">{{ $t('profile.email.passwordInfo') }}</em></p>
          <div class="form-floating has-validation">
            <input type="password" id="password" class="form-control"
                   :class="{'is-invalid': !responsePasswordValid, 'is-valid': passwordValid}"
                   v-model="password"
                   :placeholder="$t('login.password')"
                   @keyup="analyzePassword"
                   @keyup.enter="submitEmail"
                   autocomplete="new-password"
                   :disabled="isSubmitted"
            >
            <label for="password" class="form-label">{{ $t('login.password') }}</label>
            <div class="invalid-feedback" v-if="!responsePasswordValid">{{ errorResponsePassword }}</div>
            <div class="invalid-feedback" v-if="!passwordValid">{{ $t('profile.email.invalidPassword') }}</div>
          </div>
        </div>
        <div class="col-12 mt-2">
          <DefaultButton
            :label="$t('profile.email.submitEmail')"
            @click="submitEmail"
            @keyup.enter="submitEmail"
            class="btn-outline-secondary"
            :disabled="!dataValid || isSubmitted"
          />
        </div>
      </div>

      <div class="col" v-else>
        <div class="alert alert-warning d-flex align-items start" role="alert">
          <i class="bi bi-envelope me-3" style="font-size: 2rem;"></i>
          <span v-html="$t('profile.email.changeSubmitted', {email: newEmail})"></span>
        </div>
      </div>
      <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;">
        <div id="toastMessage" class="toast align-items-start bg-danger text-white" role="alert" aria-live="assertive" aria-atomic="true" v-if="showFailure">
          <div class="d-flex">
            <div class="toast-body">{{ $t('profile.email.submitFailure') }}</div>
            <button class="btn-close btn-close-white me-3 mt-3" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>

    </template>
  </profile-layout>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useUserStore} from "@/store/modules/user/user.js";
import {Toast} from "bootstrap";
import ProfileLayout from "@/components/profile/ProfileLayout";

export default {
  data() {
    return {
      newEmail: null,
      showFailure: false,
      showSuccess: false,
      password: null,
      isSubmitted: false,
      errorResponseMail: null,
      errorResponsePassword: null,
      emailValid: false,
      passwordValid: false,
    }
  },
  methods: {
    analyzeEmail() {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
      this.emailValid = emailRegex.test(this.newEmail);
    },
    analyzePassword() {
      this.passwordValid = this.password && (this.password.trim().length > 8);
    },
    submitEmail() {
      if (this.emailValid && !!this.password) {
        this.isSubmitted = true;
        this.errorResponseMail = null;
        this.errorResponsePassword = null;

        let payload = {
          'oldEmail': this.memberData.emailPrivate,
          'newEmail': this.newEmail,
          'password': this.password,
          'stvMemberId': this.memberData.memberId,
          'languageId': this.$i18n.locale,
        }
        if (this.isEmulatingMember && this.releaseMemberData) {
          payload.releaseMemberId = this.releaseMemberData.memberId;
        }
        this.changeEmail(payload).then(response => {
          if (!!response && response.status === 200) {
            this.showFailure = false;
            this.showSuccess = true;
          } else {
            if (!!response && !!response.response && !!response.response.data) {
              let errorType = response.response.data.errorType;
              let errorMessage = response.response.data.message;

              if (errorType === 'email') {
                this.errorResponseMail = this.$t('profile.email.' + errorMessage);
              } else {
                this.errorResponsePassword = this.$t('profile.email.' + errorMessage);
              }
            }
            this.showFailure = true;
          }
        }).catch(error => {
          console.log(error);
          this.showFailure = true;
        }).finally(() => {
          this.isSubmitted = false;
          const toastMessage = document.getElementById('toastMessage');
          const toast = new Toast(toastMessage);
          toast.show();
        });
      }
    },
    ...mapActions(useUserStore, ["changeEmail"])
  },
  computed: {
    dataValid() {
      return this.emailValid && !!this.password;
    },
    responseMailValid() {
      return this.errorResponseMail === null;
    },
    responsePasswordValid() {
      return this.errorResponsePassword === null;
    },
    currentMemberData() {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.releaseMemberData;
      }
      return this.memberData;
    },
    ...mapState(useMemberStore, {
      memberData: "getMemberData",
      releaseMemberData: "getReleaseMemberData",
      isEmulatingMember: "isEmulatingMember",
    })
  },
  components: {
    ProfileLayout
  },
}

</script>
