<template>
  <club-layout :title="t('club.etat.title')" :is-loading="isLoading" >
    <template #content  >
      <ClubEtatTable :clubId="route.query.clubId" @start-loading="isLoading = true" @stop-loading="isLoading = false"/>

    </template>
  </club-layout>
</template>

<script setup>
import ClubLayout from "@/components/club/ClubLayout";
import ClubEtatTable from "@/components/club/ClubEtatTable.vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {ref} from "vue";


const {t} = useI18n()
const route = useRoute();
const isLoading = ref(true);


</script>
<style scoped>
</style>


