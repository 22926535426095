<template>
  <club-layout :title="clubData?.status === 200 ? $t('institutes.list.title') + ' ' + clubData?.data.Name : $t('club.notFound.title')" :is-loading="false" >
    <template #content >
      <InstituteMemberTable :code="router.currentRoute.value.query.code" :club-id="router.currentRoute.value.query.clubId"/>
    </template>
  </club-layout>
  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import ConfirmDialog from "primevue/confirmdialog";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import InstituteMemberTable from "@/components/institute/InstituteMemberTable.vue";
import ClubLayout from "@/components/club/ClubLayout.vue";
import {useClubStore} from "@/store/modules/club/club";
import {computed} from "vue";
import {useRouter} from "vue-router";

const clubStore = useClubStore();

const router = useRouter();

const clubData = computed(() => {
  return clubStore.getClubData;
});

</script>
