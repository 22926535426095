<template>
  <profile-layout :title="currentMemberData.firstName + ' ' + currentMemberData.surname + ' / ' + $t('profile.birthday.changeBirthday')" :is-loading="isSubmitted">
    <template #content>
      <div class="row" v-if="!showSuccess">
        <div class="col">
          <div class="mb-4">
            <div class="form-floating">
              <input type="date" id="birthday" class="form-control"
                     :class="{'is-invalid': showFailure, 'is-valid': (birthdayValid && birthdayChanged)}"
                     :placeholder="$t('profile.birthday.changeBirthday')"
                     v-model="newBirthday"
                     @keyup="analyzeBirthday"
                     @change="analyzeBirthday"
                     @keyup.enter="submitBirthday">
              <label for="birthday" class="form-label">{{ $t('profile.birthday.title') }}</label>
            </div>
          </div>
          <div class="mt-2">
            <DefaultButton
              class="btn-outline-secondary"
              :label="$t('profile.birthday.saveNewBirthday')"
              @click="submitBirthday"
              @keyup.enter="submitBirthday"
              :disabled="!birthdayValid || !birthdayChanged"
            />
          </div>
        </div>
      </div>
      <div class="col" v-else>
        <div class="alert alert-success d-flex align-items-start" role="alert">
          <i class="bi bi-hand-thumbs-up me-2" style="font-size: 2rem;"></i>
          <p>
            <span v-html="$t('profile.birthday.changeSuccessful')"></span><br>
            <router-link to="profile"><i class="bi bi-person-lines-fill"></i> {{ $t('breadcrumb.changeProfile') }}</router-link>
          </p>
        </div>
      </div>
    </template>
    <div id="toastWrapper" class="position-fixed bottom-0 end-0 p-3" style="z-index:1000;"></div>
  </profile-layout>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useMemberStore} from "@/store/modules/member/member.js";
import {Toast} from "bootstrap";
import ProfileLayout from "@/components/profile/ProfileLayout";

export default {
  data() {
    return {
      newBirthday: '',
      showFailure: false,
      showSuccess: false,
      isSubmitted: false,
      birthdayValid: false,
    };
  },
  components: {
    ProfileLayout
  },
  methods: {
    analyzeBirthday() {
      this.birthdayValid = this.dateValid(this.newBirthday);
    },
    submitBirthday() {
      if (this.birthdayValid && this.birthdayChanged) {
        this.isSubmitted = true;
        let payload = {newDate: this.newBirthday};
        if (this.isEmulatingMember && this.releaseMemberData) {
          payload.releaseMemberId = this.releaseMemberData.memberId;
        }
        this.saveMemberBirthday(payload).then(response => {
          if (response && response.status === 200) {
            this.initMemberData(false);
            this.showSuccess = true;
          } else {
            console.error('saveBirtday', response);
            this.showFailure = true;
          }
          this.$router.push({
            name: 'profile',
            params: {
              showSuccess: this.showSuccess,
              message: this.showSuccess ? this.$t('profile.save.success') : this.$t('profile.save.failure'),
            },
          });
        }).catch(error => {
          this.showFailure = true;
          console.log('Error while trying to save birthday', error);
        }).finally(() => {
          window.scrollTo(0, 0);
          const bg = this.showSuccess ? 'bg-success' : 'bg-danger';
          const msg = this.showSuccess ? this.$t('profile.save.success') : this.$t('profile.save.failure');
          this.createToastMessage(bg, msg);
          const toastMessage = document.getElementById('toastMessage');
          const toast = new Toast(toastMessage);
          toast.show();
          this.isSubmitted = false;
        });
      }
    },
    createToastMessage(type, message) {
      const toastElement = document.getElementById('toastWrapper');

      const toastMessage = document.createElement('div');
      toastMessage.setAttribute('id', 'toastMessage');
      toastMessage.setAttribute('role', 'alert');
      toastMessage.setAttribute('aria-live', 'assertive');
      toastMessage.setAttribute('aria-atomic', 'true');
      toastMessage.classList.add('toast', 'align-items-center', 'text-white', type);
      toastMessage.innerHTML = `<div class="d-flex"><div class="toast-body">${message}</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>`;

      toastElement.appendChild(toastMessage);
    },
    async initMemberData(useCache) {
      if (this.isEmulatingMember && this.releaseMemberData) {
        await this.loadReleaseMemberData({useCache: useCache, releaseMemberId: this.releaseMemberData.memberId, overwrite: !useCache});
      } else {
        await this.loadMemberData(useCache, !useCache);
      }
    },
    ...mapActions(useMemberStore, [
      "saveMemberBirthday",
      "loadMemberData",
      "loadReleaseMemberData",
    ])
  },
  computed: {
    birthdayChanged() {
      return this.currentMemberData.birthday !== this.newBirthday;
    },
    currentMemberData() {
      if (this.isEmulatingMember && this.releaseMemberData) {
        return this.releaseMemberData;
      }
      return this.memberData;
    },
    ...mapState(useMemberStore, {
      memberData: "getMemberData",
      releaseMemberData: "getReleaseMemberData",
      isEmulatingMember: "isEmulatingMember",
    })
  },
  mounted() {
    this.newBirthday = this.currentMemberData.birthday;
  }
}
</script>
