<template>
  <div class="row">
    <div class="col">
      <h5 class="mb-3">{{ $t('club.honors.members.title') }}</h5>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <DataTable :value="memberHonors"
                 scrollable scroll-height="60vh"
                 resizable-columns
                 :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                 data-key="odataEtag"
                 v-model:filters="filters" filter-display="row"
                 ref="dt" :export-filename="$t('club.honors.members.title').replaceAll(' ', '_')" csv-separator=";" :export-function="csvExportTransformationFunction"
                 :paginator="memberHonors.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                 row-hover class="border-bottom">

        <template #empty>
          <div class="alert alert-info">{{ $t('club.honors.list.notFound') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <Column>
          <template #body="{data}">
            <div class="btn-group btn-group-sm">
              <Button :title="$t('delete')" :aria-label="$t('delete')"
                      unstyled class="btn btn-outline-primary"
                      icon="bi bi-trash p-0 m-0"
                      :pt="{label: {style: 'display: none'}}"
                      :loading="isDeleting" loading-icon="spinner-border spinner-border-sm"
                      @click="confirmDelete($event, data)" v-if="data.clubHonor"/>
            </div>
          </template>
        </Column>

        <Column v-for="col in columns" :key="col.field" :field="col.field"
                :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header="col.header"
                :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                :style="col.field === 'comment' ? 'max-width: 25rem; overflow:hidden; -ms-text-overflow: ellipsis; text-overflow: ellipsis;' : null"
                :exportable="col.exportable"
                :hidden="!col.visible">
          <template #body="{data}">
            <span :title="col.field === 'comment' ? formatEntry(col.type, data[col.field]) : null">{{ formatEntry(col.type, data[col.field]) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
            <InputText v-if="filterModel" v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
          </template>
        </Column>

      </DataTable>
    </div>
  </div>

  <div class="row w-100 flex-center gap-2 mb-3 mt-3">
    <Button :title="$t('export')" :label="$t('export')" @click="exportCSV($event)" unstyled class="btn btn-outline-primary datatable-export width-auto">
      <i class="bi bi-filetype-csv"></i>
    </Button>
  </div>

</template>

<script setup>
import {useHonorsStore} from "@/store/modules/honors/honors";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import moment from "moment";
import {FilterMatchMode} from "@primevue/core/api";
import {useConfirm} from "primevue/useconfirm";
import Message from "@/helpers/message";
import {useMessagesStore} from "@/store/modules/messages/messages";

const {t} = useI18n();
const honorsStore = useHonorsStore();
const messageStore = useMessagesStore();
const confirm = useConfirm();

// eslint-disable-next-line
const props = defineProps({
  clubId: String,
});

const showSuccess = ref(false);
const showFailure = ref(false);
const isDeleting = ref(false);

const memberHonors = computed(() => {
  return honorsStore.getMemberHonors;
});

const dt = ref();
const sortColumn = ref('honorId');
const onSort = (event) => {
  sortColumn.value = event.sortField;
};
const filters = ref({
  description: {value: null, matchMode: FilterMatchMode.CONTAINS},
  contactNo: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberLanguage: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberLastName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberFirstName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberSalutationCode: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberBirthday: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberAddress: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberPostcode: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberCity: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberCountry: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberEmail: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberEmailClub: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberPhone: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberPhoneMobile: {value: null, matchMode: FilterMatchMode.CONTAINS},
});
const exportCSV = () => {
  dt.value.exportCSV();
};

const columns = [
  {field: 'honorId', header: t('club.honors.id'), filterable: true, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'description', header: t('club.honors.description'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'dateOfHonor', header: t('club.member.honors.date'), filterable: true, sortable: true, exportable: true, visible: true, type: 'Date'},
  {field: 'comment', header: t('club.honors.comment'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'contactNo', header: t('club.member.stvNumber'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'memberLanguage', header: t('club.member.language'), filterable: true, sortable: true, exportable: true, visible: false, type: 'Language'},
  {field: 'memberSalutationCode', header: t('club.member.salutation'), filterable: false, sortable: true, exportable: true, visible: false, type: 'Salutation'},
  {field: 'memberLastName', header: t('club.member.surname'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'memberFirstName', header: t('club.member.firstName'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'memberBirthday', header: t('club.member.birthday'), filterable: true, sortable: true, exportable: true, visible: true, type: 'Date'},
  {field: 'memberAddress', header: t('club.member.address'), filterable: false, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'memberPostcode', header: t('club.member.postCode'), filterable: false, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'memberCity', header: t('club.member.city'), filterable: false, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'memberCountry', header: t('club.member.country'), filterable: false, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'memberEmail', header: t('club.member.emailPrivate'), filterable: false, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'memberEmailClub', header: t('club.member.emailVerein'), filterable: false, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'memberPhone', header: t('club.member.phonePrivate'), filterable: false, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'memberPhoneMobile', header: t('club.member.phoneMobile'), filterable: false, sortable: true, exportable: true, visible: false, type: 'String'},
];

const csvExportTransformationFunction = (record) => {
  switch (record.field) {
    case 'dateOfHonor':
    case 'memberBirthday':
      return formatEntry('Date', record.data);
    case 'memberSalutationCode':
      return formatEntry('Salutation', record.data);
    case 'memberLanguage':
      return formatEntry('Language', record.data);
    default:
      return String(record.data)
  }
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Salutation':
      if (value === "M") {
        return t('club.member.salutationMR');
      } else {
        return t('club.member.salutationMS')
      }
    case 'Language':
      if (value === "DE") {
        return t('languages.de');
      } else {
        return t('languages.fr')
      }
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue?.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

const confirmDelete = (event, memberHonors) => {
  confirm.require({
    target: event.currentTarget,
    header: t('club.honors.members.remove'),
    message: t('club.honors.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary'
    },
    accept: () => {
      deleteMemberHonors(memberHonors.clubNo, memberHonors.contactNo, memberHonors.lineNo);
    },
  });
}

const deleteMemberHonors = async (clubId, contactNo, lineNo) => {
  isDeleting.value = true;
  honorsStore.deleteMemberHonors(clubId, contactNo, lineNo).then(r => {
    if (r.status >= 200 && r.status < 300) {
      showSuccess.value = true;
      messageStore.addInfo(new Message('info', true, false, t('club.honors.delete.title'), '', t('club.honors.delete.success'), false, 'COMPONENT'));
    } else {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('club.honors.delete.title'), '', t(r.data), false, 'COMPONENT'));
    }
  }).catch(e => {
    showFailure.value = true;
    messageStore.addError(new Message('error', true, false, t('club.honors.delete.title'), '', e, false, 'COMPONENT'));
  }).finally(async () => {
    honorsStore.loadMemberHonorsByClub(clubId).then(() => {
      isDeleting.value = false;
    });
  });
};

</script>

