<template>
  <div v-if="etatData !== null">
  <div class="container" >
    <div class="row">
      <div class="col-12">
        {{ t('club.etat.gymnasticAdultsTitle')}}
      </div>
    </div>
    <div class="row header">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.category') }}
      </div>
      <div class="col-4">
        {{ t('club.etat.amountOfMembers') }}
      </div>
    </div>
    <div class="row" :key="n" v-for="n in 7">
      <div class="col-1">
        {{ n }}
      </div>
      <div class="col-4">
        {{ t('club.etat.kat' + n + 'Name') }}
      </div>
      <div class="col-4">
        {{ etatData['kat' + n] }}
      </div>
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4 total-cell">
        {{ t('club.etat.totalGymnasticAdults') }}
      </div>
      <div class="col-4">
        {{ etatData.totalGymnasticAdults }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
    <div class="row">
      <div class="col-1">

      </div>
      <div class="col-4">
        {{ t('club.etat.dateOfCalculation') }}
      </div>
      <div class="col-4">
        {{ formatEntry('Date', etatData.etatDate1) }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
  </div>
  <div class="container">
    <!-- Lizenz anderer Sportverband -->
    <div class="row">
      <div class="col-12">
        {{ t('club.etat.gymnasticLicenseOtherFedTitle')}}
      </div>
    </div>
    <div class="row header">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.category') }}
      </div>
      <div class="col-4">
        {{ t('club.etat.amountOfMembers') }}
      </div>
    </div>
    <div class="row" :key="n" v-for="n in [8, 9]">
      <div class="col-1">
        {{ n }}
      </div>
      <div class="col-4">
        {{ t('club.etat.kat' + n + 'Name') }}
      </div>
      <div class="col-4">
        {{ etatData['kat' + n] }}
      </div>
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4 total-cell">
        {{ t('club.etat.totalGymnasticLicenseOtherFed') }}
      </div>
      <div class="col-4">
        {{ etatData.totalGymnasticLicenseOtherFed }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.dateOfCalculation') }}
      </div>
      <div class="col-4">
        {{ formatEntry('Date', etatData.etatDate2) }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
  </div>
  <div class="container">
    <!-- Nichtturnende Erwachsene -->
    <div class="row">
      <div class="col-12">
        {{ t('club.etat.nonGymnasticAdultsTitle')}}
      </div>
    </div>
    <div class="row header">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.category') }}
      </div>
      <div class="col-4">
        {{ t('club.etat.amountOfMembers') }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-1">
        10
      </div>
      <div class="col-4">
        {{ t('club.etat.kat10Name') }}
      </div>
      <div class="col-4" v-if="etatData.automaticCalculation10and11">
        {{ etatData.kat10 }}
      </div>
      <div class="col-4" v-else>
        <input-number v-model="newKat10" class="small-input" :min="0" :max="1000000"/>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        11
      </div>
      <div class="col-4">
        {{ t('club.etat.kat11Name') }}
      </div>
      <div class="col-4" v-if="etatData.automaticCalculation10and11">
        {{ etatData.kat11 }}
      </div>
      <div class="col-4" v-else>
        <input-number v-model="newKat11" class="small-input" :min="0" :max="1000000"/>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4 total-cell">
        {{ t('club.etat.totalNonGymnasticAdults') }}
      </div>
      <div class="col-4">
        {{ etatData.totalNonGymnasticAdults }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
    <div class="row">
      <div class="col-1">
        <Checkbox v-model="etatData.automaticCalculation10and11" @change="reset10and11" :binary="true"></Checkbox>
      </div>
      <div class="col-4">
        {{ t('club.etat.automaticCalculation10and11') }}
      </div>
      <div class="col-4">

      </div>
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.dateOfCalculation') }}
      </div>
      <div class="col-4">
        {{ formatEntry('Date', etatData.etatDate3) }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
  </div>
  <div class="container">
    <!-- Junioren -->
    <div class="row">
      <div class="col-12">
        {{ t('club.etat.youngPeopleTitle')}}
      </div>
    </div>
    <div class="row header">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.category') }}
      </div>
      <div class="col-4">
        {{ t('club.etat.amountOfMembers') }}
      </div>
    </div>
    <div class="row" :key="n" v-for="n in [12, 13]">
      <div class="col-1">
        {{ n }}
      </div>
      <div class="col-4">
        {{ t('club.etat.kat' + n + 'Name') }}
      </div>
      <div class="col-4">
        {{ etatData['kat' + n] }}
      </div>
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4 total-cell">
        {{ t('club.etat.totalYoungPeople') }}
      </div>
      <div class="col-4">
        {{ etatData.totalYoungPeople }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.dateOfCalculation') }}
      </div>
      <div class="col-4">
        {{ formatEntry('Date', etatData.etatDate4) }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
  </div>
  <div class="container">
    <!-- Kinderturnen/Mukiturnen -->
    <div class="row">
      <div class="col-12">
        {{ t('club.etat.childrenAndMukiTitle')}}
      </div>
    </div>
    <div class="row header">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.category') }}
      </div>
      <div class="col-4">
        {{ t('club.etat.amountOfMembers') }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-1">
        14
      </div>
      <div class="col-4">
        {{ t('club.etat.kat14Name') }}
      </div>
      <div class="col-4" v-if="etatData.automaticCalculation14and15">
        {{ etatData.kat14 }}
      </div>
      <div class="col-4" v-else>
        <input-number v-model="newKat14" class="small-input" :min="0" :max="1000000"/>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        15
      </div>
      <div class="col-4">
        {{ t('club.etat.kat15Name') }}
      </div>
      <div class="col-4" v-if="etatData.automaticCalculation14and15">
        {{ etatData.kat15 }}
      </div>
      <div class="col-4" v-else>
        <input-number v-model="newKat15" class="small-input" :min="0" :max="1000000"/>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4 total-cell">
        {{ t('club.etat.totalChildrenAndMuki') }}
      </div>
      <div class="col-4">
        {{ etatData.totalChildrenAndMuki }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
    <div class="row">
      <div class="col-1">
        <Checkbox v-model="etatData.automaticCalculation14and15" @change="reset14and15" :binary="true"></Checkbox>
      </div>
      <div class="col-4">
        {{ t('club.etat.automaticCalculation14and15') }}
      </div>
      <div class="col-4">

      </div>
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.dateOfCalculation') }}
      </div>
      <div class="col-4">
        {{ formatEntry('Date', etatData.etatDate5) }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
  </div>
  <div class="container">
    <!-- Plusport -->
    <div class="row">
      <div class="col-12">
        {{ t('club.etat.membersPluSportTitle')}}
      </div>
    </div>
    <div class="row header">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.category') }}
      </div>
      <div class="col-4">
        {{ t('club.etat.amountOfMembers') }}
      </div>
    </div>
    <div class="row" :key="n" v-for="n in [28, 29]">
      <div class="col-1">
        {{ n }}
      </div>
      <div class="col-4">
        {{ t('club.etat.kat' + n + 'Name') }}
      </div>
      <div class="col-4">
        {{ etatData['kat' + n] }}
      </div>
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4 total-cell">
        {{ t('club.etat.totalMembersPluSport') }}
      </div>
      <div class="col-4">
        {{ etatData.totalMembersPluSport }}
      </div>
    </div>
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.dateOfCalculation') }}
      </div>
      <div class="col-4">
        {{ formatEntry('Date', etatData.etatDate6) }}
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row empty-row">
      <!-- leere Zeile -->
    </div>
    <div class="row">
      <div class="col-1">
      </div>
      <div class="col-4">
        {{ t('club.etat.numberOfMembers') }}
      </div>
      <div class="col-4">
        {{ etatData.numberOfActiveMembers }}
      </div>
    </div>

  </div>
  <div class="margin-top">
    <Button :title="$t('club.etat.saveChanges')" :label="$t('club.etat.saveChanges')" unstyled class="btn btn-outline-primary me-2"
            :hidden="!valuesChanged" @click="saveChanges"/>
    <Button :title="$t('cancel')" :label="$t('cancel')" unstyled class="btn btn-outline-secondary me-2"
            :hidden="!valuesChanged" @click="resetChanges"/>
  </div>
  <BootstrapToast/>
  </div>
</template>


<script setup>
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import ApiClient from "@/api/ApiClient";
import {useI18n} from "vue-i18n";
import moment from "moment";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import Button from "primevue/button";
import {Checkbox, InputNumber} from "primevue";

const messagesStore = useMessagesStore();
const {t} = useI18n()

const props = defineProps({
  clubId: String
})

const emit = defineEmits(['startLoading', 'stopLoading'])

const etatData = ref(null);
const newKat10 = ref(0);
const newKat11 = ref(0);
const newKat14 = ref(0);
const newKat15 = ref(0);

const oldAutoCalculate10and11 = ref(false);
const oldAutoCalculate14and15 = ref(false);

const reset10and11 = () => {
  newKat10.value = etatData.value.kat10;
  newKat11.value = etatData.value.kat11;
}

const reset14and15 = () => {
  newKat14.value = etatData.value.kat14;
  newKat15.value = etatData.value.kat15;
}

const resetChanges = () => {
  etatData.value.automaticCalculation10and11 = oldAutoCalculate10and11.value;
  etatData.value.automaticCalculation14and15 = oldAutoCalculate14and15.value;
  reset10and11();
  reset14and15();
}

const resetNewKatValues = () => {
  reset10and11();
  reset14and15();
  oldAutoCalculate10and11.value = etatData.value.automaticCalculation10and11;
  oldAutoCalculate14and15.value = etatData.value.automaticCalculation14and15
}

const valuesChanged = computed(() => {
  return newKat10.value !== etatData.value.kat10
    || newKat11.value !== etatData.value.kat11
    || newKat14.value !== etatData.value.kat14
    || newKat15.value !== etatData.value.kat15
    || oldAutoCalculate10and11.value !== etatData.value.automaticCalculation10and11
    || oldAutoCalculate14and15.value !== etatData.value.automaticCalculation14and15;
})

const saveChanges = () => {
  const requestUrl = "/api/etat";

  let requestBody = {
    odataEtag: etatData.value.odataEtag,
    clubNo: etatData.value.clubNo,
    kat10: newKat10.value,
    kat11: newKat11.value,
    kat14: newKat14.value,
    kat15: newKat15.value,
    automaticCalculation10and11: etatData.value.automaticCalculation10and11,
    automaticCalculation14and15: etatData.value.automaticCalculation14and15
  };

  ApiClient.putRequest(requestUrl, requestBody).then((response) => {
    if(response.status === 200) {
      etatData.value = response.data;
      resetNewKatValues();
      messagesStore.addInfo(new Message('info', true, false,
        t('club.etat.saveSuccessHeader'), "", t('club.etat.saveSuccess')))
    } else if (response?.data?.message) {
      messagesStore.addError(new Message("error", true, false,
        t('club.etat.errorSaveHeader'), "", response.data.message));
    } else {
      messagesStore.addError(new Message("error", true, false,
        t('club.etat.errorSaveHeader'), "", t('club.etat.errorSaveUnspecified')));
    }
  })
}

onBeforeMount(() => {
  emit('startLoading');
  reloadEtatData();
});

watch(
  () => props.clubId,
  () => {
    emit('startLoading');
    reloadEtatData();
  }
)

const reloadEtatData = () => {
  const requestUrl = "/api/etat?clubId=" + props.clubId;

  ApiClient.getRequest(requestUrl).then((response) => {
    if (response && response.status === 200) {
      etatData.value = response.data;
      resetNewKatValues();
      emit('stopLoading');
    }
  })
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    default:
      return value;
  }
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

</script>
<style scoped>
.empty-row {
  min-height: 1rem;
}

.header {
  font-weight: bold;
}

.total-cell {
  font-weight: bold;
}

.small-input > * {
  padding: 0;
}

.margin-top {
  margin-top: 1rem;
}


</style>
