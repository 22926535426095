<template>
  <club-layout :title="clubData?.status === 200 ? $t('institutes.list.title') + ' ' + clubData.data.Name : $t('club.notFound.title')" :is-loading="isLoading">
    <template #content v-if="clubData?.status === 200">
      <div class="row">
        <div class="col">

          <DataTable :value="institutes"
                     scrollable scroll-height="60vh"
                     :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                     data-key="Code"
                     v-model:filters="filters" filter-display="row"
                     :loading="isLoading"
                     ref="dt" :export-filename="$t('institutes.list.title').replaceAll(' ', '_')"
                     row-hover
                     csv-separator=";"
                     :paginator="institutes.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + $t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
                     class="border-bottom">
            <template #empty>
              <div class="alert alert-info">{{ $t('institutes.list.noInstitutesFound') }}</div>
            </template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column>
              <template #body="{data}">
                <div class="btn-group btn-group-sm me-2">
                  <Button :title="$t('institutes.edit.title', {groupName: data.Description})" :loading="isModalLoading" loading-icon="spinner-border spinner-border-sm" unstyled class="btn btn-outline-secondary" @click="openEditInstituteModal(data)">
                    <i class="bi bi-pencil"></i>
                  </Button>
                  <Button :title="$t('institutes.delete.title', {groupName: data.Description})" unstyled class="btn btn-outline-primary" @click="confirmDelete($event, data)">
                    <i class="bi bi-trash"></i>
                  </Button>
                </div>
              </template>
            </Column>

            <Column v-for="col in columns"
                    :key="col.field" :field="col.field"
                    :header="col.header"
                    :sortable="true" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    filter-header-class="table-header-filter" :show-filter-menu="false"
                    :exportable="col.exportable">
              <template #body="{data}">
                {{ data[col.field] }}
              </template>
              <template #filter="{filterModel, filterCallback}" v-if="col.filterable">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
              </template>
            </Column>
            <Column key="link" class="table-cell-link" :header-class="'tableHeader'" :exportable="false" >
              <template #body="{data}">
                <router-link :to="{name: 'institute-members', query: {clubId: clubData.data.No, code: data.Code}}" @click="isLoading = true" class="table-link">
                  {{t('breadcrumb.club.members')}}
                </router-link>
              </template>
            </Column>

          </DataTable>
        </div>
      </div>

      <div class="row w-100 flex-center gap-2 mb-3 mt-3">
        <Button title="Export" label="Export" @click="exportCSV($event)" unstyled class="btn btn-outline-primary datatable-export width-auto">
          <i class="bi bi-filetype-csv"></i>
        </Button>
        <Button :title="$t('institutes.add.title')"
                :label="$t('institutes.add.title')"
                unstyled class="btn btn-outline-secondary width-auto"
                icon="bi bi-plus-circle me-2"
                @click="openEditInstituteModal(selectedInstitute)"/>
      </div>

      <ClubMembersWithInstituteFunctionsTable :club-id="clubData.data.No" :institutes="institutes"></ClubMembersWithInstituteFunctionsTable>

    </template>
  </club-layout>

  <Dialog v-model:visible="showEditInstituteModal" modal block-scroll :header="selectedInstitute.new ? $t('institutes.add.title') : $t('institutes.edit.title', {groupName: selectedInstitute.Description})" :style="{width: '30rem'}" @hide="resetInstituteData">
    <div class="form-row mb-3" v-focustrap>
      <label for="instituteNo" class="form-label">{{ $t('institutes.list.instituteNo') }}</label>
      <InputText input-id="instituteNo" class="w-100"
                 autofocus
                 tabindex="1"
                 v-model="selectedInstitute.Institute_No" :invalid="!instituteDataValid.Institute_No"
                 :placeholder="$t('institutes.list.instituteNo')"
                 @update:modelValue="analyzeInstituteData"
                 @keyup.enter="submitInstituteData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-2" v-show="!instituteDataValid.Institute_No">{{ $t('institutes.validation.numberEmptyOrExceeded') }}</PrimeMessage>
    </div>
    <div class="form-row mb-3">
      <label for="description" class="form-label">{{ $t('institutes.list.description') }}</label>
      <InputText input-id="description" tabindex="2" v-model="selectedInstitute.Description" :invalid="!instituteDataValid.Description" :placeholder="$t('institutes.list.description')" @update:modelValue="analyzeInstituteData" class="w-100" @keyup.enter="submitInstituteData"/>
      <PrimeMessage severity="error" icon="bi bi-x-circle" class="mt-2" v-show="!instituteDataValid.Description">{{ $t('institutes.validation.nameEmptyOrExceeded') }}</PrimeMessage>
    </div>
    <div class="d-flex justify-content-between">
      <Button :label="selectedInstitute.new ? $t('save') : $t('save', {object: selectedInstitute.Description})" tabindex="3" unstyled class="btn btn-outline-primary" @click="submitInstituteData" :disabled="isSubmitted"/>
      <Button :label="$t('cancel')" tabindex="4" unstyled class="btn btn-outline-secondary" @click="showEditInstituteModal = false"/>
    </div>
  </Dialog>

  <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import ClubLayout from "@/components/club/ClubLayout.vue";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import PrimeMessage from "primevue/message";
import {useConfirm} from "primevue/useconfirm";

import {useClubStore} from "@/store/modules/club/club";
import {computed, onBeforeMount, ref} from "vue";
import {useInstituteStore} from "@/store/modules/institute/institute";
import {useMessagesStore} from "@/store/modules/messages/messages";
import {useI18n} from "vue-i18n";

import {FilterMatchMode} from '@primevue/core/api';
import Message from "@/helpers/message";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import ClubMembersWithInstituteFunctionsTable from "@/components/institute/ClubMembersWithInstituteFunctionsTable.vue";

const clubStore = useClubStore();
const instituteStore = useInstituteStore();
const messageStore = useMessagesStore();
const confirm = useConfirm();

const isLoading = ref(false);
const isModalLoading = ref(false);
const showEditInstituteModal = ref(false);
const isSubmitted = ref(false);
const showSuccess = ref(false);
const showFailure = ref(false);

const dt = ref();
const sortColumn = ref('Institute_No');
const filters = ref({
  Code: {value: null, matchMode: FilterMatchMode.CONTAINS},
  Institute_No: {value: null, matchMode: FilterMatchMode.CONTAINS},
  Description: {value: null, matchMode: FilterMatchMode.CONTAINS},
});

const {t} = useI18n();

const clubData = computed(() => {
  return clubStore.getClubData;
});

const institutes = computed(() => {
  return instituteStore.getInstituteListData;
});

const columns = [
  {field: 'Code', header: t('institutes.list.code'), filterable: false, exportable: true, visible: true},
  {field: 'Institute_No', header: t('institutes.list.instituteNo'), filterable: true, exportable: true, visible: true},
  {field: 'Description', header: t('institutes.list.description'), filterable: true, exportable: true, visible: true},
];

const selectedInstitute = ref({
  Club_No: clubData.value?.data.No,
  Code: '',
  Institute_No: '',
  Description: '',
  new: true,
});

const instituteDataValid = ref({
  Institute_No: false,
  Description: false,
});

const allDataValid = computed(() => {
  return allTrue(instituteDataValid.value);
});

const onSort = (event) => {
  return sortColumn.value = event.sortField;
};

const exportCSV = () => {
  dt.value.exportCSV();
};

const resetInstituteData = () => {
  selectedInstitute.value = {
    Club_No: clubData.value?.data.No,
    Code: '',
    Institute_No: '',
    Description: '',
    new: true,
  };
};

const openEditInstituteModal = (institute) => {
  isModalLoading.value = true;
  selectedInstitute.value = JSON.parse(JSON.stringify(institute));
  analyzeInstituteData();
  showEditInstituteModal.value = true;
  isModalLoading.value = false;
};

const analyzeInstituteData = () => {
  for (const key in selectedInstitute.value) {
    if (key === 'Institute_No') {
      instituteDataValid.value[key] = isNotEmptyOrWithinMaxLength(selectedInstitute.value[key], 30);
    } else if (key === 'Description') {
      instituteDataValid.value[key] = isNotEmptyOrWithinMaxLength(selectedInstitute.value[key], 50);
    }
  }
};

const submitInstituteData = () => {
  if (allDataValid.value) {
    isSubmitted.value = true;
    showSuccess.value = false;
    showFailure.value = false;
    isLoading.value = true;
    instituteStore.saveOrUpdateInstitute(selectedInstitute.value).then(r => {
      if (r.status === 200) {
        showSuccess.value = true;
        messageStore.addInfo(new Message('info', true, false, t('institutes.save.success'), '', t('institutes.save.success'), true, 'COMPONENT'));
      } else {
        showFailure.value = true;
        messageStore.addError(new Message('error', true, false, t('institutes.save.failure'), '', t(r.data), true, 'COMPONENT'));
      }
    }).catch(e => {
      console.error(e);
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('institutes.save.failure'), '', e.error, true, 'COMPONENT'));
    }).finally(async () => {
      isLoading.value = false;
      isSubmitted.value = false;
      if (showSuccess.value) {
        showEditInstituteModal.value = false;
        await reloadInstitutes(selectedInstitute.value.Club_No);
      }
    });
  }
};

const confirmDelete = (event, institute) => {
  confirm.require({
    target: event.currentTarget,
    header: t('institutes.delete.confirm'),
    message: t('institutes.delete.question'),
    icon: 'bi bi-exclamation-triangle',
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    acceptProps: {
      label: t('delete'),
      unstyled: true,
      class: 'btn btn-outline-danger',
    },
    accept: () => {
      deleteInstitute(institute);
    },
  });
};

const deleteInstitute = async (institute) => {
  instituteStore.deleteInstitute(institute).then(r => {
    if (r.status >= 200 && r.status < 300) {
      showSuccess.value = true;
      messageStore.addInfo(new Message('info', true, false, t('institutes.delete.confirm'), '', t('institutes.delete.success', {groupName: institute.Description}), true, 'COMPONENT'));
    } else {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', t(r.data), true, 'COMPONENT'));
    }
  }).catch(e => {
    console.error(e);
    showFailure.value = true;
    messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', e, true, 'COMPONENT'));
  }).finally(() => {
    reloadInstitutes(institute.Club_No);
  });
};

onBeforeMount(() => {
  reloadInstitutes(clubData.value?.data.No)
})

const reloadInstitutes = async (clubNo) => {
  await instituteStore.loadInstitutesForClub(clubNo);
};

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
};

const isNotEmptyOrWithinMaxLength = (value, maxLength) => {
  return !isEmpty(value) && value?.trim().length < maxLength;
};

const isEmpty = (value) => {
  return value?.trim() === '';
};
</script>
